import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'
import dataPicker from '../../data-picker'

const domains: SiteEnvDataType<DomainByEnv>[] = [
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Korean',
    },
    data: {
      HOME: 'https://www-dev.gmarket.co.kr',
      SIGN_IN:
        'https://signinssl-dev.gmarket.co.kr/login/login?prmtdisp=Y&url={0}',
      SIGN_IN_ORDER:
        'https://signinssl-dev.gmarket.co.kr/Login/LoginOrder?url={0}',
      SMILE_CLUB: 'https://smileclub-dev.gmarket.co.kr',
      UNINVERSE_CLUB: 'https://club-dev.gmarket.co.kr',
      SSL_MEMBER: 'https://sslmember2-dev.gmarket.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SmileClub/Gate?returnURL={0}',
      SCRIPT: '//script-dev.gmarket.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.gmarket.co.kr',
      PICS: '//pics-dev.gmarket.co.kr',
      ADDRESS_BOOK:
        '//mmyg-dev.gmarket.co.kr/ShippingAddress/LatestShippingAddressList?returnUrl={0}&closeUrl={1}&choice={2}&isSmileClub={3}&isSmileFresh={4}',
      ADDRESS_BOOK_ADD:
        '//mmyg-dev.gmarket.co.kr/ShippingAddress/GetAddressDetail?returnUrl={0}&closeUrl={1}',
      SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/SelfAuthGate/SelfAuthForSimpleBuyer?returnUrl={0}',
      ECOUPON_SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SelfAuthGate/OnlyMobileSelfAuth?fType=FECCHN&returnUrl={0}&closeUrl={1}',
      MY_COUPON: 'http://gbank-dev.gmarket.co.kr/Home/MyCoupon',
      ITEM_NO_IMAGE: '//pics-dev.gmarket.co.kr/pc/ko/pay/cart/img_empty.jpg',
      VIP: 'http://item-dev.gmarket.co.kr/Item?goodsCode={0}',
      MONTELENA_SERVICE_NAME: 'GMKT-Cart',
      MONTELENA_API_KEY: '86c75a88-7f5e-4a3e-94a0-206aaad2cb3b',
      MY_PAGE: 'http://myg-dev.gmarket.co.kr',
      EXPRESS_SHOP_HOME: 'https://www-dev.gmarket.co.kr/n/expressshop/home',
      SMILE_DELIVERY_HOME: 'https://www-dev.gmarket.co.kr/n/smiledelivery',
      EMARTMALL_HOME: 'https://www-dev.gmarket.co.kr/n/emartmall',
      FREE_DELIVERY_SMILE_DELIVERY_CATEGORY:
        'https://www.gmarket.co.kr/n/smiledelivery/category?categoryCode=1000007&s=8&p=1&f=d:f',
      SMILE_DELIVERY_CATEGORY:
        'https://www.gmarket.co.kr/n/smiledelivery/category?categoryCode=1000007&s=8&p=1',
      MINI_SHOP: 'http://minishop-dev.gmarket.co.kr/{0}',
      EXPRESS_SHOP_SELLER:
        'https://www-dev.gmarket.co.kr/n/expressshop/home?sellerId={0}',
      OVERSEA_SHIPPING_GUIDE:
        'http://global-dev.gmarket.co.kr/InternationalShippingInfo/Kr',
      EXPRESS_SHOP_TIMETABLE:
        'https://www-dev.gmarket.co.kr/n/expressshop/address-book?readOnly=true&sellerId={0}',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.gmarket.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Korean',
    },
    data: {
      HOME: 'https://m-dev.gmarket.co.kr',
      SIGN_IN: 'https://mobile-dev.gmarket.co.kr/Login/Login?URL={0}',
      SIGN_IN_ORDER:
        'https://mobile-dev.gmarket.co.kr/Login/Login?type=direct&merge=true&rtnurl={0}',
      SMILE_CLUB: 'https://smileclub-dev.gmarket.co.kr',
      UNINVERSE_CLUB: 'https://club-dev.gmarket.co.kr',
      SSL_MEMBER: 'https://sslmember2-dev.gmarket.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SmileClub/Gate?returnURL={0}',
      SCRIPT: '//script-dev.gmarket.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.gmarket.co.kr',
      PICS: '//pics-dev.gmarket.co.kr',
      ADDRESS_BOOK:
        '//mmyg-dev.gmarket.co.kr/ShippingAddress/LatestShippingAddressList?returnUrl={0}&closeUrl={1}&choice={2}&isSmileClub={3}&isSmileFresh={4}&closeType=B',
      ADDRESS_BOOK_ADD:
        '//mmyg-dev.gmarket.co.kr/ShippingAddress/GetAddressDetail?returnUrl={0}&closeUrl={1}&closeType=B',
      SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SelfAuthGate/SelfAuthGate?returnUrl={0}',
      ECOUPON_SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SelfAuthGate/OnlyMobileSelfAuth?fType=MECCHN&returnUrl={0}&closeUrl={1}',
      MY_COUPON: 'http://mmyg-dev.gmarket.co.kr/Coupon/MyCoupon',
      ITEM_NO_IMAGE: '//pics-dev.gmarket.co.kr/pc/ko/pay/cart/img_empty.jpg',
      VIP: 'http://mitem-dev.gmarket.co.kr/Item?goodsCode={0}',
      MONTELENA_SERVICE_NAME: 'GMKT-Cart',
      MONTELENA_API_KEY: '86c75a88-7f5e-4a3e-94a0-206aaad2cb3b',
      MY_PAGE: 'https://mmyg-dev.gmarket.co.kr',
      EXPRESS_SHOP_HOME: 'https://m-dev.gmarket.co.kr/n/expressshop/home',
      SMILE_DELIVERY_HOME: 'https://m-dev.gmarket.co.kr/n/smiledelivery',
      EMARTMALL_HOME:
        'https://m-dev.gmarket.co.kr/?sectionSeq=33001&pageTypeSeq=13001',
      FREE_DELIVERY_SMILE_DELIVERY_CATEGORY:
        'https://m.gmarket.co.kr/n/smiledelivery/category?categoryCode=1000007&s=8&p=1&f=d:f',
      SMILE_DELIVERY_CATEGORY:
        'https://m.gmarket.co.kr/n/smiledelivery/category?categoryCode=1000007&s=8&p=1',
      MINI_SHOP: 'https://m-dev.gmarket.co.kr/n/minishop/{0}',
      ECOUPON_FE: 'https://ecoupon-dev.gmarket.co.kr',
      EXPRESS_SHOP_SELLER:
        'https://m-dev.gmarket.co.kr/n/expressshop/home?sellerId={0}',
      GO_APP:
        'http://mobile-dev.gmarket.co.kr/AppGate/V3?link={0}%3FappGatePopupA%3Dtrue%26p6dl%3Donsite01111231220111191358&app=gmkt&store=true',
      EXPRESS_SHOP_TIMETABLE:
        'https://m-dev.gmarket.co.kr/n/expressshop/address-book?readOnly=true&sellerId={0}&returnUrl={1}',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.gmarket.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Global',
    },
    data: {
      HOME: 'http://global-dev.gmarket.co.kr/Home/Main',
      SIGN_IN: 'https://gsigninssl-dev.gmarket.co.kr/LogIn/LogIn?URL={0}',
      NOT_ENCODE_SIGN_IN_RETURN_URL: true,
      SMILE_CLUB: 'https://smileclub-dev.gmarket.co.kr',
      UNINVERSE_CLUB: '',
      SSL_MEMBER: 'https://sslmember2-dev.gmarket.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE: '',
      SCRIPT: '//script-dev.gmarket.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.gmarket.co.kr',
      PICS: '//pics-dev.gmarket.co.kr',
      ADDRESS_BOOK: '',
      SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/SelfAuthGate/SelfAuthForSimpleBuyer?returnUrl={0}',
      ECOUPON_SELF_AUTH: '',
      MY_COUPON: 'http://ggbank-dev.gmarket.co.kr/Home/MyCoupon',
      ITEM_NO_IMAGE: '//pics-dev.gmarket.co.kr/pc/ko/pay/cart/img_empty.jpg',
      VIP: 'http://global-dev.gmarket.co.kr/item?goodsCode={0}',
      MONTELENA_SERVICE_NAME: 'GMKT-Cart',
      MONTELENA_API_KEY: '86c75a88-7f5e-4a3e-94a0-206aaad2cb3b',
      MY_PAGE: 'https://gmyg-dev.gmarket.co.kr',
      SMILE_DELIVERY_HOME:
        'http://gpromotion.gmarket.co.kr/Plan/PlanView?sid=164257',
      MINI_SHOP:
        'http://gshop-dev.gmarket.co.kr/Minishop/GlobalMinishop?CustNo={0}',
      OVERSEA_SHIPPING_GUIDE:
        'http://global-dev.gmarket.co.kr/InternationalShippingInfo',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.gmarket.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Global',
    },
    data: {
      HOME: 'http://mg-dev.gmarket.co.kr/',
      SIGN_IN: 'https://gsigninssl-dev.gmarket.co.kr/mobilelogin/login?URL={0}',
      NOT_ENCODE_SIGN_IN_RETURN_URL: true,
      SMILE_CLUB: '',
      UNINVERSE_CLUB: '',
      SSL_MEMBER: 'https://sslmember2-dev.gmarket.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE: '',
      SCRIPT: '//script-dev.gmarket.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.gmarket.co.kr',
      PICS: '//pics-dev.gmarket.co.kr',
      ADDRESS_BOOK: '',
      SELF_AUTH:
        'https://sslmember2-dev.gmarket.co.kr/mobile/SelfAuthGate/SelfAuthGate?returnUrl={0}',
      ECOUPON_SELF_AUTH: '',
      MY_COUPON: 'http://mgmyg-dev.gmarket.co.kr/CashBalance/MyCoupon',
      ITEM_NO_IMAGE: '//pics-dev.gmarket.co.kr/pc/ko/pay/cart/img_empty.jpg',
      VIP: 'http://mg-dev.gmarket.co.kr/Item?goodsCode={0}',
      MONTELENA_SERVICE_NAME: 'GMKT-Cart',
      MONTELENA_API_KEY: '86c75a88-7f5e-4a3e-94a0-206aaad2cb3b',
      MY_PAGE: 'https://mgmyg-dev.gmarket.co.kr',
      SMILE_DELIVERY_HOME:
        'http://mg.gmarket.co.kr/Promotion/Plan?lcId=&sid=164257',
      MINI_SHOP: 'http://mg-dev.gmarket.co.kr/Shop/{0}',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.gmarket.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'PC',
    },
    data: {
      HOME: 'http://www.auction.co.kr',
      SIGN_IN: 'https://memberssl-dev.auction.co.kr/authenticate/?url={0}',
      SMILE_CLUB: 'https://smileclub-dev.auction.co.kr',
      UNINVERSE_CLUB: 'https://club-dev.auction.co.kr',
      SSL_MEMBER: 'https://memberssl-dev.auction.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://memberssl-dev.auction.co.kr/fp/SmileClub/SmileClubGate.aspx?nexturl={0}',
      SCRIPT: '//script-dev.auction.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.auction.co.kr',
      PICS: '//pics-dev.auction.co.kr',
      ADDRESS_BOOK:
        '//address-book-dev.auction.co.kr/mini?returnUrl={0}&clubYn={3}',
      ADDRESS_BOOK_ADD:
        '//address-book-dev.auction.co.kr/address/0?openType=mini&returnUrl={0}',
      SELF_AUTH:
        'https://memberssl-dev.auction.co.kr/Common/VerifyMain.aspx?url={0}',
      ECOUPON_SELF_AUTH:
        'https://memberssl-dev.auction.co.kr/Common/m/VerifyIdentityPopup.aspx?ctype=N&mtype=Z&next={0}&close={1}',
      MY_COUPON:
        'http://reward-dev.auction.co.kr/MyCoupon/BuyerCouponList.aspx',
      ITEM_NO_IMAGE: '//pics-dev.auction.co.kr/pc/single/cart/img_empty.jpg',
      VIP: 'http://itempage3-dev.auction.co.kr/DetailView.aspx?itemno={0}',
      MONTELENA_SERVICE_NAME: 'IAC-Cart',
      MONTELENA_API_KEY: '6c26cb5d-f96d-4575-966b-b8e59ab8f4e5',
      MY_PAGE: '',
      EXPRESS_SHOP_HOME: 'http://corners.auction.co.kr/Homeplus/Default.aspx',
      SMILE_DELIVERY_HOME: 'http://corners2.auction.co.kr/SmileDelivery',
      FREE_DELIVERY_SMILE_DELIVERY_CATEGORY:
        'https://www.auction.co.kr/n/smiledelivery/category?categoryCode=1000007&p=1&f=d:f',
      SMILE_DELIVERY_CATEGORY:
        'https://www.auction.co.kr/n/smiledelivery/category?categoryCode=1000007&p=1',
      MINI_SHOP: 'http://stores-dev.auction.co.kr/{0}',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.auction.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'Mobile',
    },
    data: {
      HOME: 'http://mobile-dev.auction.co.kr',
      SIGN_IN:
        'https://signin-dev.auction.co.kr/Authenticate/MobileLogin.aspx?url={0}',
      SMILE_CLUB: 'https://smileclub-dev.auction.co.kr',
      UNINVERSE_CLUB: 'https://club-dev.auction.co.kr',
      SSL_MEMBER: 'https://memberssl-dev.auction.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://memberssl-dev.auction.co.kr/fp/SmileClub/SmileClubGate.aspx?nexturl={0}',
      SCRIPT: '//script-dev.auction.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.auction.co.kr',
      PICS: '//pics-dev.auction.co.kr',
      ADDRESS_BOOK:
        '//address-book-dev.auction.co.kr/mini?returnUrl={0}&clubYn={3}',
      ADDRESS_BOOK_ADD:
        '//address-book-dev.auction.co.kr/address/0?openType=mini&returnUrl={0}',
      SELF_AUTH:
        'https://memberssl-dev.auction.co.kr/Common/VerifyMain.aspx?url={0}',
      ECOUPON_SELF_AUTH:
        'https://memberssl-dev.auction.co.kr/Common/m/VerifyIdentityPopup.aspx?ctype=N&mtype=Z&next={0}&close={1}',
      MY_COUPON:
        'https://reward-dev.auction.co.kr/MyCoupon/Mobile/BuyerCouponList.aspx',
      ITEM_NO_IMAGE:
        '//pics-dev.auction.co.kr/mobile/single/cart/img_empty.jpg',
      VIP: 'http://mitem-dev.auction.co.kr/vip?itemNo={0}',
      MONTELENA_SERVICE_NAME: 'IAC-Cart',
      MONTELENA_API_KEY: '6c26cb5d-f96d-4575-966b-b8e59ab8f4e5',
      MY_PAGE: '',
      EXPRESS_SHOP_HOME: 'http://m.auction.co.kr/Mart/Oneday',
      SMILE_DELIVERY_HOME: 'http://m.auction.co.kr/SmileDelivery/Home',
      FREE_DELIVERY_SMILE_DELIVERY_CATEGORY:
        'https://m.auction.co.kr/n/smiledelivery/category?categoryCode=1000007&p=1&f=d:f',
      SMILE_DELIVERY_CATEGORY:
        'https://m.auction.co.kr/n/smiledelivery/category?categoryCode=1000007&p=1',
      MINI_SHOP: 'http://m-dev.auction.co.kr/Shop/{0}',
      UNIVERSE_CLUB_AUTO_RENEWAL:
        'https://smileclub-dev.auction.co.kr/myclub?menu=apply-auto-order',
    },
  },
  {
    env: {
      tenantType: 'G9',
      deviceType: 'PC',
    },
    data: {
      HOME: 'http://www-dev.g9.co.kr',
      SIGN_IN:
        'https://www-dev.g9.co.kr/Member/CustomerService/AllLogin?redirectUrl={0}',
      SIGN_IN_ORDER:
        'https://www-dev.g9.co.kr/Member/CustomerService/OrderLogin?redirectUrl={0}',
      SMILE_CLUB: 'https://smileclub-dev.g9.co.kr',
      UNINVERSE_CLUB: '',
      SSL_MEMBER: '',
      SSL_MEMBER_SMILE_CLUB_GATE: '',
      SCRIPT: '//script-dev.g9.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.g9.co.kr',
      PICS: '//pics-dev.g9.co.kr',
      ADDRESS_BOOK: '',
      SELF_AUTH:
        'http://www-dev.g9.co.kr/Member/CustomerService/FirstAuth?redirectUrl={0}',
      ECOUPON_SELF_AUTH: '',
      MY_COUPON: '',
      ITEM_NO_IMAGE: '//pics.g9.co.kr/201602/common/no_image_t8.gif',
      VIP: 'https://www-dev.g9.co.kr/Display/VIP/Index/{0}',
      MONTELENA_SERVICE_NAME: 'G9-Cart',
      MONTELENA_API_KEY: '',
      MY_PAGE: 'http://www-dev.g9.co.kr/Member/MyPage',
      MINI_SHOP: '',
      FAVORITES: 'http://www-dev.g9.co.kr/Display/Favorite',
      PROMOTION: 'http://rpp.g9.co.kr/g9benefit',
      G9_BANNER_IMG: '//pics.g9.co.kr/event/banner/BM_orderBNR_pc_360x86_2.jpg',
      UNIVERSE_CLUB_AUTO_RENEWAL: '',
    },
  },
  {
    env: {
      tenantType: 'G9',
      deviceType: 'Mobile',
    },
    data: {
      HOME: 'http://m.g9.co.kr',
      SIGN_IN: 'https://m-dev.g9.co.kr/Member.htm?redirectUrl={0}',
      SIGN_IN_ORDER:
        'https://m-dev.g9.co.kr/Member.htm?returnCart=Y&redirectUrl={0}',
      SMILE_CLUB: 'https://smileclub-dev.g9.co.kr',
      UNINVERSE_CLUB: '',
      SSL_MEMBER: '',
      SSL_MEMBER_SMILE_CLUB_GATE: '',
      SCRIPT: '//script-dev.g9.co.kr',
      SMILE_SERVICE: 'https://smsvcs-dev.g9.co.kr',
      PICS: '//pics-dev.g9.co.kr',
      ADDRESS_BOOK: '',
      SELF_AUTH:
        'https://m-dev.g9.co.kr/Member.htm#/Member/FirstAuth?redirectUrl={0}',
      ECOUPON_SELF_AUTH: '',
      MY_COUPON: '',
      ITEM_NO_IMAGE: '//pics.g9.co.kr/201602/common/no_image_t8.gif',
      VIP: 'https://m-dev.g9.co.kr/VIP.htm#/Display/VIP/{0}',
      MONTELENA_SERVICE_NAME: 'G9-Cart',
      MONTELENA_API_KEY: '',
      MY_PAGE: 'https://m-dev.g9.co.kr/MyInfo.htm?_dummy#/MyPage/PurchaseInfo',
      MINI_SHOP: '',
      FAVORITES: 'http://m-dev.g9.co.kr/MyInfo.htm#/Display/FavoriteList',
      PROMOTION: 'http://rpp.g9.co.kr/g9benefit',
      G9_BANNER_IMG: '//pics.g9.co.kr/event/banner/BM_orderBNR_m_750x180_2.jpg',
      UNIVERSE_CLUB_AUTO_RENEWAL: '',
    },
  },
]

const cartHost = 'https://cart-dev{devset}.{tenant}.co.kr'
// const cartHost = 'http://cart-local.{tenant}.co.kr:9090'

const common: DomainCommon = {
  CART: cartHost,
  CART_HOME: `${cartHost}/{language}/{device}/cart`,
  CART_FE_CLIENT: `${cartHost}/{language}/{device}`,
  CART_FE_CLIENT_MOBILE: `${cartHost}/{language}/m`,
  CART_FE_SERVER: `${cartHost}/server/{language}/{device}`,
  DOMAIN_FOR_COOKIE: '.{tenant}.co.kr',
  CHECKOUT:
    'https://checkout-dev.{tenant}.co.kr/{language}/{device}/checkout?chid={0}',
}

const domainsByEnv = dataPicker<DomainByEnv>(domains, siteEnv)
if (!domainsByEnv) {
  throw new Error('no domain data')
}

const result: Domain = {
  ...domainsByEnv,
  ...common,
}

export default result
