import _, {
  findIndex,
  intersection,
  isEqual,
  map,
  pickBy,
  reduce,
  uniq,
} from 'lodash'
import { createReducer } from 'typesafe-actions'
import { ItemGroupMap } from '~/cart/modules/types'
import domains from '~/data/domains'
import { __ } from '~/lib/i18n'
import { getParameters } from '~/lib/param-parser'
import { BranchServiceType } from '~/types/enums'
import {
  INIT_CART,
  REMOVE_CART_UNIT_LIST,
  SELECT_OR_DESELECT_CART_UNIT,
  SET_BSD_EVENTS,
  SET_CART_TAB,
  SET_IS_APPLIED_COUPON_QUANTITY_CHANGED,
  SET_ITEM_PURCHASE_BENEFITS,
  SET_SMILE_CARD_NUDGE_COUPONS,
  UPDATE_BUNDLE_KEY,
  UPDATE_CART_NUDGING_TYPES,
  UPDATE_CART_UNITS,
  UPDATE_CART_UNIT_APPLAY_COUPONS,
  UPDATE_DISCOUNTS,
  UPDATE_ENCRYPTED_SELLER_KEYS,
  UPDATE_EXTRA_DISCOUNT_NUDGE,
  UPDATE_SIGNAL,
} from './actions'
import {
  CartAction,
  CartItemTreeView,
  CartState,
  CartUnit,
  CartUnitBasicPriceType,
  CartUnitCounts,
  ExhibitDiscountNudgeList,
  ItemGroupView,
  SellerGroupView,
  ShippingGroupView,
} from './types'

const initialState: CartState = {
  currentCartTab: 'All',
  cartItemTreeView: {
    cartUnitIdList: [],
    sellerGroups: [],
  },
  selectedCartUnitIdList: [],
  cartUnitList: [],
  arrivalEstimatedDays: [],
  bundleKeyMappings: [],
  smileCardNudgeCoupons: [],
  encodedSellerKeys: {},
  cartNudgingTypes: [],
  isAppliedCouponQuantityChanged: false,
  exhibitDiscountNudge: [],
}

/**
 * 판매자별 묶음(스마일배송 예외처리)
 * @param result
 * @param cartUnit
 * @returns {*}
 */
const _sellerGroupReducer = (
  result: SellerGroupView[],
  cartUnit: CartUnit,
): SellerGroupView[] => {
  if (cartUnit.cartUnitType === 'SmileFresh' && domains.EMARTMALL_HOME) {
    // 스마일프레시 묶어준다
    const resultIdx = findIndex(result, (_) => _.type === 'SmileFresh')
    if (resultIdx === -1) {
      result.push({
        key: 'fresh',
        type: 'SmileFresh',
        shopName: '이마트몰',
        shopLink: domains.EMARTMALL_HOME,
        cartUnitIdList: [cartUnit.cartUnitId],
        shippingGroups: [],
      })
    } else {
      result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
    }
  } else if (
    cartUnit.cartUnitType === 'SmileDelivery' &&
    domains.SMILE_DELIVERY_HOME
  ) {
    // 스마일배송을 묶어준다
    const resultIdx = findIndex(result, (_) => _.type === 'SmileDelivery')
    if (resultIdx === -1) {
      result.push({
        key: 'smile',
        type: 'SmileDelivery',
        shopName: __('ESCROW_BASKET_TEXT_235', '스마일배송'),
        shopLink: domains.SMILE_DELIVERY_HOME,
        cartUnitIdList: [cartUnit.cartUnitId],
        shippingGroups: [],
      })
    } else {
      result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
    }
  } else if (cartUnit.cartUnitType === 'ExpressShop') {
    // CHKP-7287 당배관 기본주소 삭제 기능 추가됨에 따라 당배관 브랜치 정보 넘어오지 않는 경우 존재, sellerKey 기준으로 묶어준다
    const branchCode =
      cartUnit.expressShopBranch?.branchCode || cartUnit.seller.sellerKey
    // 당일배송을 묶어준다
    const resultIdx = findIndex(
      result,
      (_) => _.type === 'ExpressShop' && _.key === branchCode,
    )
    if (resultIdx === -1) {
      result.push({
        key: branchCode,
        type: 'ExpressShop',
        shopName: cartUnit.seller.miniShopName,
        shopLink: cartUnit.seller.shopLink,
        expressShopType:
          cartUnit.expressShopBranch?.expressShopType || 'Unknown',
        branchName: cartUnit.expressShopBranch?.branchName,
        cartUnitIdList: [cartUnit.cartUnitId],
        shippingGroups: [],
      })
    } else {
      result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
    }
  } else {
    // 판매자 번호가 같은 상품을 묶어준다
    const resultIdx = findIndex(
      result,
      (_) => _.type === 'Seller' && _.key === cartUnit.seller.sellerKey,
    )
    if (resultIdx === -1) {
      result.push({
        type: 'Seller',
        key: cartUnit.seller.sellerKey,
        shopName: cartUnit.seller.miniShopName,
        shopLink: cartUnit.seller.shopLink,
        cartUnitIdList: [cartUnit.cartUnitId],
        shippingGroups: [],
      })
    } else {
      result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
    }
  }
  return result
}

/**
 * 배송 정책별 묶음
 * @param result
 * @param cartUnit
 * @returns {*}
 */
const _shippingGroupReducer = (
  result: ShippingGroupView[],
  cartUnit: CartUnit,
): ShippingGroupView[] => {
  const key = cartUnit.shippingPolicyKey

  const resultIdx = findIndex(result, (_) => _.key === key)
  if (resultIdx === -1) {
    result.push({
      key: key,
      bundleKey: cartUnit.bundleKey,
      cartUnitIdList: [cartUnit.cartUnitId],
      itemGroups: [],
    })
  } else {
    result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
  }
  return result
}
/**
 * 상품별 묶음
 * @param result
 * @param cartUnit
 * @returns {*}
 */
const _itemGroupReducer = (
  result: ItemGroupView[],
  cartUnit: CartUnit,
): ItemGroupView[] => {
  const resultIdx = findIndex(result, (_) => _.key === cartUnit.item.itemNo)
  if (resultIdx === -1) {
    result.push({
      key: cartUnit.item.itemNo,
      cartUnitIdList: [cartUnit.cartUnitId],
    })
  } else {
    result[resultIdx].cartUnitIdList.push(cartUnit.cartUnitId)
  }
  return result
}

/**
 * View를 위한 주문상품 트리구조
 * 이걸 가져다가 쓰게되면 re-rendering 너무 빈번하여
 * checkoutUnit 업데이트시 처리하도록
 * @returns {*}
 * @param cartUnitList
 */
const _getCartItemTree = (cartUnitList: CartUnit[]): CartItemTreeView => {
  return {
    cartUnitIdList: cartUnitList.map((x) => x.cartUnitId),
    sellerGroups: reduce(cartUnitList, _sellerGroupReducer, []).map(
      (sellerGroup) => ({
        ...sellerGroup,
        shippingGroups: reduce(
          cartUnitList.filter((x) =>
            sellerGroup.cartUnitIdList.includes(x.cartUnitId),
          ),
          _shippingGroupReducer,
          [],
        ).map((shippingGroup) => ({
          ...shippingGroup,
          itemGroups: reduce(
            cartUnitList.filter((x) =>
              shippingGroup.cartUnitIdList.includes(x.cartUnitId),
            ),
            _itemGroupReducer,
            [],
          ),
        })),
      }),
    ),
  }
}

const _getItemOptionsAdditionalPrice = (cartUnit: CartUnit): number => {
  return cartUnit.item.options.reduce(
    (result, option) => result + option.optionPrice,
    0,
  )
}

const _getItemAdditionsTotalAmount = (cartUnit: CartUnit): number => {
  return cartUnit.item.additions.reduce(
    (result, addition) => result + addition.price * addition.quantity,
    0,
  )
}

export const getCartUnitById = (
  state: CartState,
  cartUnitId: number,
): CartUnit | undefined => {
  return state.cartUnitList.find((x) => x.cartUnitId === cartUnitId)
}

export const getCartUnitListByIdList = (
  state: CartState,
  cartUnitIdList: number[],
): CartUnit[] => {
  return state.cartUnitList.filter((x) => cartUnitIdList.includes(x.cartUnitId))
}

export const getCartUnitIdList = (state: CartState): number[] => {
  return state.cartUnitList.map((cartUnit) => cartUnit.cartUnitId)
}

export const hasPartnershipDiscount = (
  state: CartState,
  cartUnitId: number,
): boolean => {
  const cartUnit = getCartUnitById(state, cartUnitId)
  return !!cartUnit?.discounts.some(
    (discount) => discount.discountType === 'Partnership',
  )
}

export const getCartUnitAppliedCouponMap = (
  state: CartState,
): {
  cartUnitIdList: number[]
  appliedCouponIssueNoes: number[]
}[] =>
  state.cartItemTreeView.sellerGroups
    .flatMap((sellerGroup) => sellerGroup.shippingGroups)
    .flatMap((shippingGroup) => shippingGroup.itemGroups)
    .flatMap(({ cartUnitIdList }) => {
      // BE에서 정확히 그룹에 맞춰서 주었을것이라 기대하며 첫번째 주문단위에 달린 쿠폰정보만 사용
      const cartUnit = getCartUnitById(state, cartUnitIdList[0])
      if (
        cartUnit &&
        cartUnit.appliedCouponIssueNoes &&
        cartUnit.appliedCouponIssueNoes.length > 0
      ) {
        return [
          {
            appliedCouponIssueNoes: cartUnit.appliedCouponIssueNoes,
            cartUnitIdList,
          },
        ]
      } else {
        return []
      }
    })

export const getCartUnitPriceBasicInfo = (
  state: CartState,
  cartUnitId: number,
  currentBranchServiceType: BranchServiceType,
): CartUnitBasicPriceType => {
  const cartUnit = getCartUnitById(state, cartUnitId)
  if (!cartUnit) {
    return {
      cartUnitItemPrice: 0,
      cartUnitEbayDiscountPrice: 0,
      cartUnitBundleDiscountPrice: 0,
      cartUnitSellerDiscountPrice: 0,
      cartUnitPartnershipDiscountPrice: 0,
      cartUnitAdditionsTotalPrice: 0,
      cartUnitOptionsAdditionalPrice: 0,
      cartUnitBranchAdditionalPrice: 0,
      cartUnitBranchAdditionalTotalPrice: 0,
    }
  }

  // 전체 금액

  const itemOptionsAdditionalPrice = _getItemOptionsAdditionalPrice(cartUnit)

  const itemAdditionsTotalAmount = _getItemAdditionsTotalAmount(cartUnit)

  const selectedSmileFreshBranch = cartUnit.smileFreshBranches.find((x) =>
    x.branchServiceTypes.includes(currentBranchServiceType),
  )

  const branchAdditionalPrice =
    cartUnit.cartUnitType === 'ExpressShop'
      ? cartUnit.expressShopBranch?.branchAdditionalPrice || 0
      : cartUnit.cartUnitType === 'SmileFresh'
      ? selectedSmileFreshBranch?.branchAdditionalPrice || 0
      : 0
  const branchAdditionalTotalAmount = cartUnit.quantity * branchAdditionalPrice

  const itemPrice =
    cartUnit.quantity *
      (cartUnit.item.itemSellPrice +
        itemOptionsAdditionalPrice +
        branchAdditionalPrice) +
    itemAdditionsTotalAmount

  const {
    ebayDiscountPrice,
    bundleDiscountPrice,
    sellerDiscountPrice,
    partnershipDiscountPrice,
  } = (cartUnit.discounts || []).reduce(
    (result, discount) => {
      const discountPrice = discount.discountPrice

      switch (discount.discountType) {
        case 'Ebay':
          result.ebayDiscountPrice += discountPrice
          break
        case 'Seller':
          result.sellerDiscountPrice += discountPrice
          break
        case 'Bundle':
          result.bundleDiscountPrice += discountPrice
          break
        case 'Partnership':
          result.partnershipDiscountPrice += discountPrice
          break
      }
      return result
    },
    {
      ebayDiscountPrice: 0,
      bundleDiscountPrice: 0,
      sellerDiscountPrice: 0,
      partnershipDiscountPrice: 0,
    },
  )

  return {
    cartUnitItemPrice: itemPrice,
    cartUnitEbayDiscountPrice: ebayDiscountPrice,
    cartUnitBundleDiscountPrice: bundleDiscountPrice,
    cartUnitSellerDiscountPrice: sellerDiscountPrice,
    cartUnitPartnershipDiscountPrice: partnershipDiscountPrice,
    cartUnitAdditionsTotalPrice: itemAdditionsTotalAmount,
    cartUnitOptionsAdditionalPrice: itemOptionsAdditionalPrice,
    cartUnitBranchAdditionalPrice: branchAdditionalPrice,
    cartUnitBranchAdditionalTotalPrice: branchAdditionalTotalAmount,
  }
}

/**
 * isEqual 필수
 * @param state
 */
export const getCurrentCartTree = (state: CartState): CartItemTreeView => {
  switch (state.currentCartTab) {
    case 'SmileFresh':
      return {
        cartUnitIdList: state.cartUnitList
          .filter((x) => x.cartUnitType === 'SmileFresh')
          .map((x) => x.cartUnitId),
        sellerGroups: state.cartItemTreeView.sellerGroups.filter(
          (x) => x.type === 'SmileFresh',
        ),
      }
    case 'SmileDelivery':
      return {
        cartUnitIdList: state.cartUnitList
          .filter((x) => x.cartUnitType === 'SmileDelivery')
          .map((x) => x.cartUnitId),
        sellerGroups: state.cartItemTreeView.sellerGroups.filter(
          (x) => x.type === 'SmileDelivery',
        ),
      }
    case 'ExpressShop':
      return {
        cartUnitIdList: state.cartUnitList
          .filter((x) => x.cartUnitType === 'ExpressShop')
          .map((x) => x.cartUnitId),
        sellerGroups: state.cartItemTreeView.sellerGroups.filter(
          (x) => x.type === 'ExpressShop',
        ),
      }
    default:
      return state.cartItemTreeView
  }
}

/**
 * shallowEqual 필수
 * @param state
 */
export const getCartUnitCounts = (state: CartState): CartUnitCounts => {
  const cartUnitList = state.cartUnitList
  return {
    All: cartUnitList.length,
    SmileFresh: cartUnitList.filter((x) => x.cartUnitType === 'SmileFresh')
      .length,
    SmileDelivery: cartUnitList.filter(
      (x) => x.cartUnitType === 'SmileDelivery',
    ).length,
    ExpressShop: cartUnitList.filter((x) => x.cartUnitType === 'ExpressShop')
      .length,
  }
}

export const getCurrentCartUnitList = (state: CartState): CartUnit[] => {
  switch (state.currentCartTab) {
    case 'SmileFresh':
      return state.cartUnitList.filter((x) => x.cartUnitType === 'SmileFresh')
    case 'SmileDelivery':
      return state.cartUnitList.filter(
        (x) => x.cartUnitType === 'SmileDelivery',
      )
    case 'ExpressShop':
      return state.cartUnitList.filter((x) => x.cartUnitType === 'ExpressShop')
    default:
      return state.cartUnitList
  }
}

export const getIsAllBuyUnavailable = (
  state: CartState,
  cartUnitIdList?: number[],
): boolean => {
  const targetCartUnitList = getCurrentCartUnitList(state).filter(
    (cartUnit) =>
      !cartUnitIdList || cartUnitIdList.includes(cartUnit.cartUnitId),
  )
  return targetCartUnitList.every((x) => !x.isBuyAvailable)
}

export const getSameItemGroupCartUnitIds = (
  state: CartState,
  cartUnitId: number,
): number[] => {
  const cartTree = state.cartItemTreeView
  const itemGroups = cartTree.sellerGroups.flatMap((sellerGroup) =>
    sellerGroup.shippingGroups.flatMap(
      (shippingGroup) => shippingGroup.itemGroups,
    ),
  )
  return (
    itemGroups.find((itemGroup) =>
      itemGroup.cartUnitIdList.includes(cartUnitId),
    )?.cartUnitIdList || []
  )
}

export const getSameShippingGroupCartUnitIds = (
  state: CartState,
  cartUnitId: number,
  onlySelected?: boolean,
): number[] => {
  const cartTree = state.cartItemTreeView
  const shippingGroups = cartTree.sellerGroups.flatMap(
    (sellerGroup) => sellerGroup.shippingGroups,
  )
  const cartUnitIds =
    shippingGroups.find((shippingGroup) =>
      shippingGroup.cartUnitIdList.includes(cartUnitId),
    )?.cartUnitIdList || []
  if (onlySelected) {
    return intersection(cartUnitIds, state.selectedCartUnitIdList)
  } else {
    return cartUnitIds
  }
}

export const getEncodedSellerKey = (
  state: CartState,
  sellerKey: string,
): string | undefined => {
  return state.encodedSellerKeys[sellerKey]
}

export const getIsAppliedCouponQuantityChanged = (
  state: CartState,
): boolean => {
  return state.isAppliedCouponQuantityChanged
}

export const getShippingGroupKeyByItemGroup = (
  state: CartState,
  itemGroup: ItemGroupView,
): string => {
  const firstCartUnit = getCartUnitById(state, itemGroup.cartUnitIdList[0])
  return firstCartUnit?.shippingPolicyKey || ''
}

export const getItemGroupMapKey = (
  state: CartState,
  itemGroup: ItemGroupView,
): string => {
  return `${getShippingGroupKeyByItemGroup(state, itemGroup)}-${itemGroup.key}`
}

export const getItemGroupMap = (state: CartState): ItemGroupMap => {
  return state.cartItemTreeView.sellerGroups
    .flatMap((sellerGroup) => sellerGroup.shippingGroups)
    .flatMap((shippingGroup) => shippingGroup.itemGroups)
    .reduce((acc, curr) => {
      const key = getItemGroupMapKey(state, curr)
      acc[key] = curr
      return acc
    }, {} as ItemGroupMap)
}

/**
 * UI상 보여지는 순으로 리스트 정렬
 * signalCartUnitId: App Push로 장바구니 진입시 CartUnit 최우선으로 변경
 */
export const sortSignalCartUnits = (state: CartState): CartUnit[] => {
  const signalCartUnitId = getParameters().signalCartUnitId
  let cartUnitIdList = state.cartItemTreeView.sellerGroups
    .flatMap((sellerGroup) => sellerGroup.shippingGroups)
    .flatMap((shippingGroup) => shippingGroup.itemGroups)
    .flatMap((itemGroups) => itemGroups.cartUnitIdList)

  if (signalCartUnitId) {
    cartUnitIdList = [
      signalCartUnitId,
      ...cartUnitIdList.filter((id) => id !== signalCartUnitId),
    ]
  }
  const unitMap = new Map(
    state.cartUnitList.map((unit) => [unit.cartUnitId, unit]),
  )
  return cartUnitIdList.reduce((acc: CartUnit[], id: number) => {
    const unit = unitMap.get(id)
    if (unit) {
      acc.push(unit)
    }
    return acc
  }, [])
}

export const urlWithQueryParameters = (
  urlLink: string | undefined,
  params: Array<Record<string, number | string | undefined>>,
): string => {
  const url = new URL(urlLink || '')
  params.forEach((param) => {
    for (const [key, value] of Object.entries(param)) {
      if (value) {
        url.searchParams.set(key, String(value))
      }
    }
  })
  return String(url)
}

export const getIsSignalVisible = (
  cartUnits: CartUnit[],
  cartTree: CartItemTreeView,
): boolean => {
  const cartUnitMap = _.keyBy(cartUnits, 'cartUnitId')
  return _.some(cartTree.cartUnitIdList, (cartUnitId) => {
    const existingItem = cartUnitMap[cartUnitId]
    return existingItem?.signal && existingItem?.signal.signalType !== 'NONE'
  })
}

export const getExhibitDiscountNudge = (
  ExhibitState: ExhibitDiscountNudgeList[] | undefined,
): ExhibitDiscountNudgeList | undefined => {
  if (
    ExhibitState &&
    ExhibitState.length > 0 &&
    ExhibitState[0].dcPolicyType === 'EXHIBIT'
  ) {
    return ExhibitState[0]
  }
  return undefined
}

/**
 * 선택된 item group 목록 조회
 * @param state
 * @param selectedCartUnitIds
 * @return key : '{shippingGroupKey}-{itemGroupKey}'
 */
export const getSelectedItemGroupMap = (
  state: CartState,
  selectedCartUnitIds: number[],
): ItemGroupMap => {
  return pickBy(getItemGroupMap(state), ({ cartUnitIdList }) =>
    cartUnitIdList.some((id) => selectedCartUnitIds.includes(id)),
  )
}

/**
 * 선택된 item group key 목록 조회
 * @param state
 * @param selectedCartUnitIds
 * @return key : '{shippingGroupKey}-{itemGroupKey}'
 */
export const getSelectedItemGroupMapKeys = (
  state: CartState,
  selectedCartUnitIds: number[],
): string[] => {
  return map(
    getSelectedItemGroupMap(state, selectedCartUnitIds),
    (_, key) => key,
  )
}

const checkout = createReducer<CartState, CartAction>(initialState, {
  [INIT_CART]: (state, { payload }) => {
    const newState = {
      ...state,
      ...payload,
    }

    return {
      ...newState,
      cartItemTreeView: _getCartItemTree(newState.cartUnitList),
    }
  },
  [SET_CART_TAB]: (state, { payload }) => ({
    ...state,
    currentCartTab: payload,
  }),
  [UPDATE_CART_UNITS]: (state, { payload }) => {
    const newState = {
      ...state,
      cartUnitList: payload.cartUnitList
        .filter(
          (x) =>
            !state.cartUnitList.some(
              (cartUnit) => cartUnit.cartUnitId === x.cartUnitId,
            ),
        )
        .concat(
          state.cartUnitList.map((cartUnit) => {
            const newCartUnit = payload.cartUnitList.find(
              (x) => x.cartUnitId === cartUnit.cartUnitId,
            )
            if (newCartUnit) {
              return {
                ...newCartUnit,
                item: {
                  ...newCartUnit.item,
                  bsd: cartUnit.item.bsd,
                },
              }
            } else {
              return {
                ...cartUnit,
                item: {
                  ...cartUnit.item,
                  bsd: cartUnit.item.bsd,
                },
              }
            }
          }),
        ),
      cartNudgingTypes: payload.cartNudgingTypes
        ? payload.cartNudgingTypes
        : state.cartNudgingTypes,
    }

    return {
      ...newState,
      cartItemTreeView: _getCartItemTree(newState.cartUnitList),
    }
  },
  [UPDATE_DISCOUNTS]: (state, { payload: discountPrices }) => ({
    ...state,
    cartUnitList: state.cartUnitList.map((cartUnit) => {
      const discountPrice = discountPrices.find(
        (x) => x.cartUnitId === cartUnit.cartUnitId,
      )

      if (!discountPrice || !discountPrice.discounts) {
        return cartUnit
      } else {
        return {
          ...cartUnit,
          discounts: discountPrice.discounts,
          isItemDiscountAgreement: !!discountPrice.isItemDiscountAgreement,
        }
      }
    }),
  }),
  [UPDATE_SIGNAL]: (state, { payload: signalList }) => ({
    ...state,
    cartUnitList: state.cartUnitList.map((cartUnit) => {
      const signal = signalList.find(
        (signal) => signal.cartUnitId === cartUnit.cartUnitId,
      )
      if (signal) {
        return {
          ...cartUnit,
          signal,
        }
      } else {
        return cartUnit
      }
    }),
  }),
  [UPDATE_BUNDLE_KEY]: (state, { payload: bundleKeyMappings }) => {
    // 맵핑이 같다면 변경하지 않는다
    // 쓸데없이 업데이트시 itemTree 를 다시 만들기때문에 많은 리소스가 소모된다
    if (isEqual(bundleKeyMappings, state.bundleKeyMappings)) {
      return state
    }

    const cartUnitList = state.cartUnitList.map<CartUnit>((cartUnit) => ({
      ...cartUnit,
      bundleKey: (
        bundleKeyMappings.find((bundle) =>
          bundle.cartUnitIdList.some((id) => id === cartUnit.cartUnitId),
        ) || { bundleKey: undefined }
      ).bundleKey,
    }))
    return {
      ...state,
      cartUnitList,
      cartItemTreeView: _getCartItemTree(cartUnitList),
      bundleKeyMappings: bundleKeyMappings,
    }
  },
  [SELECT_OR_DESELECT_CART_UNIT]: (state, { payload }) => {
    const selected = state.selectedCartUnitIdList

    if (payload.isSelect) {
      return {
        ...state,
        selectedCartUnitIdList: uniq(selected.concat(payload.cartUnitIdList)),
      }
    } else {
      return {
        ...state,
        selectedCartUnitIdList: selected.filter(
          (x) => !payload.cartUnitIdList.includes(x),
        ),
      }
    }
  },
  [REMOVE_CART_UNIT_LIST]: (state, { payload }) => {
    const newState = {
      ...state,
      cartUnitList: state.cartUnitList
        .filter((cartUnit) => !payload.includes(cartUnit.cartUnitId))
        .map((cartUnit) => {
          const newCartUnit = {
            ...cartUnit,
            duplicateCartUnitIds: cartUnit.duplicateCartUnitIds.filter(
              (duplicateCartUnitId) => !payload.includes(duplicateCartUnitId),
            ),
          }

          if (newCartUnit.duplicateCartUnitIds.length <= 1) {
            return {
              ...newCartUnit,
              duplicateCartUnitIds: [],
              hasDuplicates: false,
            }
          }

          return newCartUnit
        }),
    }

    return {
      ...newState,
      cartItemTreeView: _getCartItemTree(newState.cartUnitList),
    }
  },
  [SET_SMILE_CARD_NUDGE_COUPONS]: (state, { payload }) => {
    return {
      ...state,
      smileCardNudgeCoupons: payload,
    }
  },
  [UPDATE_ENCRYPTED_SELLER_KEYS]: (state, { payload }) => {
    return {
      ...state,
      encodedSellerKeys: {
        ...state.encodedSellerKeys,
        ...payload,
      },
    }
  },
  [UPDATE_CART_NUDGING_TYPES]: (state, { payload }) => {
    return {
      ...state,
      cartNudgingTypes: state.cartNudgingTypes.filter(
        (cartNudgingType) => cartNudgingType !== payload,
      ),
    }
  },
  [SET_BSD_EVENTS]: (state, { payload: itemBsdResponse }) => ({
    ...state,
    cartUnitList: state.cartUnitList.map((cartUnit) => {
      const itemBsd = itemBsdResponse.find(
        (x) => x.itemNo === cartUnit.item.itemNo,
      )

      if (!itemBsd) {
        return cartUnit
      } else {
        return {
          ...cartUnit,
          item: {
            ...cartUnit.item,
            bsd: {
              bigSaleTitle: itemBsd.bigSaleTitle || '',
              bigSmile: itemBsd.bigSmile || false,
              startDate: itemBsd.startDate || '',
              endDate: itemBsd.endDate || '',
            },
          },
        }
      }
    }),
  }),
  [SET_ITEM_PURCHASE_BENEFITS]: (state, { payload: benefitResponse }) => ({
    ...state,
    cartUnitList: state.cartUnitList.map((cartUnit) => {
      const benefits = benefitResponse.find(
        (x) => x.itemNo === cartUnit.item.itemNo,
      )

      if (!benefits || !benefits.purchaseBenefits) {
        return cartUnit
      } else {
        return {
          ...cartUnit,
          item: {
            ...cartUnit.item,
            itemPurchaseBenefitList: benefits.purchaseBenefits.map((x) => {
              return {
                benefitType: x.benefitType || 'Unknown',
                benefitValue: x.benefitValue,
              }
            }),
          },
        }
      }
    }),
  }),
  [SET_IS_APPLIED_COUPON_QUANTITY_CHANGED]: (state, { payload }) => ({
    ...state,
    isAppliedCouponQuantityChanged: payload,
  }),
  [UPDATE_CART_UNIT_APPLAY_COUPONS]: (state, { payload }) => {
    return {
      ...state,
      cartUnitList: state.cartUnitList.map((cartUnit) => {
        if (cartUnit.cartUnitId === payload.cartUnitId) {
          cartUnit.appliedCouponIssueNoes = payload.couponIssueNoes
        }
        return cartUnit
      }),
    }
  },
  [UPDATE_EXTRA_DISCOUNT_NUDGE]: (state, { payload }) => {
    return {
      ...state,
      exhibitDiscountNudge:
        state.selectedCartUnitIdList.length > 0 ? payload : [],
    }
  },
})

export default checkout
