import simpleSwitch from '~/lib/simple-switch'
import siteEnv from '~/lib/site-env'
import av from './av'
import development from './development'
import development1 from './development/1'
import development2 from './development/2'
import avGlobal from './global/av'
import developmentGlobal from './global/development'
import development1Global from './global/development/1'
import development2Global from './global/development/2'
import localGlobal from './global/local'
import productionGlobal from './global/production'
import local from './local'
import production from './production'

const domainList = {
  local: {
    ...local,
    ...localGlobal,
  },
  development: {
    ...development,
    ...developmentGlobal,
  },
  development1: {
    ...development1,
    ...development1Global,
  },
  development2: {
    ...development2,
    ...development2Global,
  },
  production: {
    ...production,
    ...productionGlobal,
  },
  av: {
    ...av,
    ...avGlobal,
  },
}

type devType = 'development' | 'development1' | 'development2'

export type DomainByEnv = {
  HOME: string
  SIGN_IN: string
  SIGN_IN_ORDER?: string
  NOT_ENCODE_SIGN_IN_RETURN_URL?: boolean
  SMILE_CLUB: string
  UNINVERSE_CLUB: string
  SSL_MEMBER: string
  SSL_MEMBER_SMILE_CLUB_GATE: string
  SCRIPT: string
  SMILE_SERVICE: string
  PICS: string
  ADDRESS_BOOK?: string
  ADDRESS_BOOK_ADD?: string
  SELF_AUTH: string
  ECOUPON_SELF_AUTH: string
  MY_COUPON: string
  ITEM_NO_IMAGE: string
  VIP: string
  MONTELENA_SERVICE_NAME: string
  MONTELENA_API_KEY: string
  MY_PAGE: string
  EXPRESS_SHOP_HOME?: string
  SMILE_DELIVERY_HOME?: string
  EMARTMALL_HOME?: string
  SMILE_DELIVERY_CATEGORY?: string
  FREE_DELIVERY_SMILE_DELIVERY_CATEGORY?: string
  MINI_SHOP: string
  ECOUPON_FE?: string //G마켓만 사용
  EXPRESS_SHOP_SELLER?: string //G마켓만(왜?)
  GO_APP?: string //G마켓 모바일만 앱다운 넛징
  OVERSEA_SHIPPING_GUIDE?: string
  EXPRESS_SHOP_TIMETABLE?: string
  FAVORITES?: string
  PROMOTION?: string
  G9_BANNER_IMG?: string
  UNIVERSE_CLUB_AUTO_RENEWAL: string
}

export type DomainCommon = {
  CART: string
  CART_HOME: string
  CART_FE_CLIENT: string
  CART_FE_CLIENT_MOBILE: string
  CART_FE_SERVER: string
  CHECKOUT: string
  CHECKOUT_LOCAL?: string
  DOMAIN_FOR_COOKIE: string
}

export type Domain = Readonly<DomainByEnv & DomainCommon>

const _domains: DomainByEnv & DomainCommon = ((): Domain => {
  const env = siteEnv.env || 'production'
  const devSet = parseInt(siteEnv.devSet || '0') || 0
  if (!env || !domainList[env]) {
    throw new Error(`REACT_APP_ENV cannot be ${env}`)
  }

  const devEnvWithDevSet = simpleSwitch<devType, number>(devSet)
    .on(1, 'development1')
    .on(2, 'development2')
    .otherwise('development')

  // 개별 개발망 config는 다른것만 취하도록..!
  if (env === 'development' && domainList[devEnvWithDevSet]) {
    return {
      ...domainList.development,
      ...domainList[devEnvWithDevSet],
    }
  }

  if (env === 'local') {
    return {
      ...domainList.development,
      ...domainList.local,
    }
  }

  if (env === 'av') {
    return {
      ...domainList.production,
      ...domainList.av,
    }
  }

  const domain = domainList[env]
  return {
    ...domain,
  }
})()

const domains: Domain = Object.keys(_domains).reduce<
  DomainByEnv & DomainCommon
>((result, key) => {
  const castedKey = key as keyof Domain
  const environmentValue = process.env['REACT_APP_DOMAINS_' + key]
  const value = environmentValue || _domains[castedKey]
  if (value) {
    return {
      ...result,
      [castedKey]:
        typeof value === 'string'
          ? value.replace(/{(language|device|tenant|devset)}/gi, (match) => {
              switch (match) {
                case '{language}':
                  return simpleSwitch<string, typeof siteEnv.languageType>(
                    siteEnv.languageType,
                  )
                    .on('English', 'en')
                    .on('Chinese', 'cn')
                    .otherwise('ko')
                case '{device}':
                  return simpleSwitch<string, typeof siteEnv.deviceType>(
                    siteEnv.deviceType,
                  )
                    .on('PC', 'pc')
                    .otherwise('m')
                case '{tenant}':
                  return simpleSwitch<string, typeof siteEnv.tenantType>(
                    siteEnv.tenantType,
                  )
                    .on('Auction', 'auction')
                    .on('G9', 'g9')
                    .otherwise('gmarket')
                case '{devset}':
                  return siteEnv.devSet || ''
              }
              return ''
            })
          : value,
    }
  }
  return result
}, _domains)

export default domains
