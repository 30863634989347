import React from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import EmptyCart from '~/cart/gmarket/ko/common/components/EmptyCart'
import CartSellerGroupContainer from '~/cart/gmarket/ko/common/containers/CartItemList/CartSellerGroupContainer'
import BuyBoxRecommendationContainer from '~/cart/gmarket/ko/common/containers/CartRecommend/BuyBoxRecommendationContainer'
import CartBtRecommendContainer from '~/cart/gmarket/ko/common/containers/CartRecommend/CartBtRecommendContainer'
import CartToolbarContainer from '~/cart/gmarket/ko/common/containers/CartToolbarContainer'
import CouponRecommendationContainer from '~/cart/gmarket/ko/common/containers/CouponRecommendationContainer'
import OrderSummaryContainer from '~/cart/gmarket/ko/common/containers/OrderSummary/OrderSummaryContainer'
import UniverseClubNudgingContainer from '~/cart/gmarket/ko/common/containers/UniverseClubNudgingContainer'
import ExpressShopAddressContainer from '~/cart/gmarket/ko/mobile/containers/ExpressShopAddressContainer'
import MobileSmileDeliveryAddressContainer from '~/cart/gmarket/ko/mobile/containers/SmileDelivery/SmileDeliveryAddressContainer'
import useWindowScrollEffect from '~/cart/hooks/useWindowScrollEffect'
import {
  getCurrentCartTree,
  getCurrentCartUnitList,
  getIsSignalVisible,
} from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const BuyBoxRecommendation = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const isLogin = useSelector((state: RootState) => state.buyer.isLogin)

  return isLogin ? (
    <BuyBoxRecommendationContainer>{children}</BuyBoxRecommendationContainer>
  ) : (
    <>{children}</>
  )
}

const CartBodyContainer = (): JSX.Element => {
  logRender()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const currentCartCount = useSelector(
    (state: RootState) => getCurrentCartUnitList(state.cart).length,
  )

  const cartTree = useSelector(
    (state: RootState) => getCurrentCartTree(state.cart),
    isEqual,
  )

  const isSignalVisible = useSelector((state: RootState) =>
    getIsSignalVisible(state.cart.cartUnitList, cartTree),
  )

  useWindowScrollEffect(
    ({ x, y }) => {
      window.scrollTo(x, y)
    },
    [cartTree],
  )

  if (currentCartCount === 0) {
    return <EmptyCart isMobile={isMobile} />
  } else {
    return (
      <div id="cart_body">
        <div className="inner_cont">
          {isMobile && (
            <>
              <UniverseClubNudgingContainer />
              <ExpressShopAddressContainer />
              <MobileSmileDeliveryAddressContainer />
              <CartToolbarContainer />
            </>
          )}
          <BuyBoxRecommendation>
            <CouponRecommendationContainer>
              <div id="cart_list">
                <h3 className="for_a11y">나의 장바구니 목록</h3>
                <ol className="basket_list_group">
                  {cartTree.sellerGroups.map((sellerGroup) => (
                    <CartSellerGroupContainer
                      key={sellerGroup.key}
                      sellerGroup={sellerGroup}
                    />
                  ))}
                  {isSignalVisible && (
                    <li className="text__signal-guide">
                      가격인하 안내는 최근 30일 평균가를 기준으로 합니다.
                    </li>
                  )}
                </ol>
                <div className="fake_cursorpoint" />
              </div>
            </CouponRecommendationContainer>
          </BuyBoxRecommendation>
          <CartBtRecommendContainer />
          <OrderSummaryContainer />
        </div>
      </div>
    )
  }
}

export default CartBodyContainer
