import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsUniverseClubNudging } from '~/cart/modules/buyer/reducer'
import { openOneClickJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'
import { goSignIn } from '~/lib/utils'

const UniverseClubNudgingContainer = (): JSX.Element => {
  logRender()

  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const isUniverseClubNudging = useSelector((state: RootState) =>
    getIsUniverseClubNudging(state),
  )

  const onClickOpenOneClickJoin = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isMember) {
        goSignIn()
        return
      }
      dispatch(openOneClickJoin(e.currentTarget, 'coupon'))
    },
    [dispatch, isMember],
  )

  useEffect(() => {
    if (
      areaCodes.CLUB_NUDGING_ACTIVED &&
      isUniverseClubNudging &&
      currentCartTab !== 'SmileDelivery'
    ) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.CLUB_NUDGING_ACTIVED,
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          club_nudge_tab: currentCartTab,
        },
        'UniverseClubNudgingContainer',
      )
    }
  }, [currentCartTab, isUniverseClubNudging])

  if (
    !isUniverseClubNudging ||
    (isMobile && currentCartTab !== 'All') ||
    (!isMobile && currentCartTab === 'SmileDelivery')
  ) {
    return <></>
  }

  return (
    <div
      className={
        isMobile
          ? 'box__smiledelivery-wrap smiledelivery--wrap'
          : 'box__freemembership'
      }
    >
      <div className="box__smiledelivery">
        <p className="text__smiledelivery">
          <span
            className={
              isMobile
                ? 'image__club sprite__cart'
                : 'image__club sprite__coupon--before'
            }
          >
            <span className="for-a11y">유니버스 클럽 로고</span>
          </span>{' '}
          {currentCartTab === 'SmileFresh'
            ? '클럽 회원님께만 드리는 이마트몰 15% 쿠폰!'
            : '클럽 회원님께만 드리는 15% 쿠폰!'}
        </p>
        <CustomAnchor
          className="link__free-membershop sprite__cart--after"
          data-montelena-acode={areaCodes.CLUB_NUDGING_CLICK}
          data-montelena-club_nudge_tab={currentCartTab}
          onClick={onClickOpenOneClickJoin}
        >
          무료체험
        </CustomAnchor>
      </div>
    </div>
  )
}

export default UniverseClubNudgingContainer
