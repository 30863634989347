import React from 'react'
import CartSelector from '~/cart/gmarket/ko/common/containers/CartSelector'
import SmileFreshSwitchContainer from '~/cart/gmarket/ko/common/containers/SmileFreshSwitchContainer'
import { SellerGroupView, SellerGroupViewType } from '~/cart/modules/cart/types'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import { CartTabType, ExpressShopType } from '~/types/enums'

const getShopNameClass = (
  sellerGroupViewType: SellerGroupViewType,
  expressShopType?: ExpressShopType,
): string => {
  if (sellerGroupViewType === 'ExpressShop') {
    const className = 'shop_name icon sprite__cart tit_logo '
    switch (expressShopType) {
      case 'GSFresh':
        return className + 'tit_logo_gsfresh'
      case 'LotteSuper':
        return className + 'tit_logo_lottesuper'
      case 'LotteMart':
        return className + 'tit_logo_lottemart'
      default:
        return className + 'tit_logo_homeplus'
    }
  } else if (sellerGroupViewType === 'SmileDelivery') {
    return 'shop_name sprite__cart tit_logo tit_logo_smiledelivery'
  } else if (sellerGroupViewType === 'SmileFresh') {
    return 'shop_name sprite__cart tit_logo tit_logo_smilefresh'
  }

  return 'shop_name'
}

const getShopLink = (sellerGroup: SellerGroupView): string => {
  if (sellerGroup.type === 'SmileDelivery' && domains.SMILE_DELIVERY_HOME) {
    return domains.SMILE_DELIVERY_HOME
  } else if (sellerGroup.type === 'SmileFresh' && domains.EMARTMALL_HOME) {
    return domains.EMARTMALL_HOME
  } else if (
    sellerGroup.type === 'ExpressShop' &&
    domains.EXPRESS_SHOP_SELLER
  ) {
    switch (sellerGroup.expressShopType) {
      case 'GSFresh':
        return formatString(domains.EXPRESS_SHOP_SELLER, 'gsfresh300')
      case 'LotteSuper':
        return formatString(domains.EXPRESS_SHOP_SELLER, 'lottesuper')
      case 'LotteMart':
        // dev:testlotte / real: lottemartm
        return formatString(domains.EXPRESS_SHOP_SELLER, 'lottemartm')
      default:
        return formatString(domains.EXPRESS_SHOP_SELLER, 'SHomeplus')
    }
  } else {
    return formatString(domains.MINI_SHOP, sellerGroup.shopLink)
  }
}

const CartSellerGroupHeader = ({
  sellerGroup,
  currentCartTab,
  onClickOpenExpressTimeTable,
}: {
  sellerGroup: SellerGroupView
  currentCartTab: CartTabType
  onClickOpenExpressTimeTable: (
    expressShopType: ExpressShopType,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div
      className={`cart--basket_header ${
        sellerGroup.type === 'SmileDelivery'
          ? 'box__smiledelivery'
          : sellerGroup.type === 'SmileFresh'
          ? 'box__smilefresh'
          : ''
      }`}
    >
      {currentCartTab !== 'SmileFresh' &&
        currentCartTab !== 'SmileDelivery' && (
          <CartSelector
            id={`${sellerGroup.type}_${sellerGroup.key}`}
            wrapperTitle="샵 내 상품 전체선택"
            cartUnitIdList={sellerGroup.cartUnitIdList}
            aria-label={`${sellerGroup.shopName} 전체`}
            data-montelena-acode={areaCodes.SELECT_ALL_MINISHOP}
          />
        )}
      <CustomAnchor
        className="link_area"
        href={getShopLink(sellerGroup)}
        data-montelena-acode="{getAreaCode(sellerGroup)}"
      >
        <strong
          className={getShopNameClass(
            sellerGroup.type,
            sellerGroup.expressShopType,
          )}
        >
          {sellerGroup.shopName}
        </strong>
        <span className="for_a11y">으로 이동</span>
      </CustomAnchor>
      {sellerGroup.branchName && (
        <em className="emphasis">{sellerGroup.branchName}</em>
      )}
      {currentCartTab === 'ExpressShop' &&
        sellerGroup.type === 'ExpressShop' &&
        sellerGroup.expressShopType && (
          <CustomAnchor
            onClick={onClickOpenExpressTimeTable(sellerGroup.expressShopType)}
            className="btn_table_delivery"
          >
            배송시간표
          </CustomAnchor>
        )}
      {sellerGroup.type === 'SmileFresh' && (
        <SmileFreshSwitchContainer currentCartTab={currentCartTab} />
      )}
    </div>
  )
}

export default CartSellerGroupHeader
