import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSmileFreshBranchServiceType } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { getIsSeperatedBranches } from '~/cart/modules/smile-fresh/reducer'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { CartTabType } from '~/types/enums'

const CartHeaderContainer = ({
  currentCartTab,
}: {
  currentCartTab: CartTabType
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isSeperatedBranches = useSelector((state: RootState) =>
    getIsSeperatedBranches(state.smileFresh),
  )
  const currentBranchServiceType = useSelector(
    (state: RootState) => state.smileFresh.currentBranchServiceType,
  )

  const onClickToggle = (): void => {
    dispatch(toggleSmileFreshBranchServiceType())
  }

  if (!isSeperatedBranches) {
    return <></>
  }

  return (
    <div className="box__smilefresh-toggle">
      <label htmlFor="smilefresh-list-type" className="for-a11y">
        낮밤배송/새벽배송 리스트 변경
      </label>
      <button
        id="smilefresh-list-type"
        type="button"
        className="button__toggle"
        role="switch"
        aria-checked={currentBranchServiceType === 'Reserve' ? 'true' : 'false'}
        aria-label="낮밤"
        data-montelena-current_tab_type={currentCartTab}
        onClick={onClickToggle}
        data-montelena-acode={areaCodes.SMILE_FRESH_BRANCH_SERVICE_TYPE}
        data-montelena-delivery_time={
          currentBranchServiceType === 'Reserve' ? 'allday' : 'dawn'
        }
      >
        <span aria-live="assertive" className="text__list-type for-a11y">
          새벽배송 리스트 보기
        </span>
        <span aria-hidden="true" className="text__emphasis item--today">
          <span className="for-a11y">낮밤 배송</span>
        </span>
        <span aria-hidden="true" className="text__emphasis item--dawn">
          <span className="for-a11y">새벽 배송</span>
        </span>
      </button>
    </div>
  )
}

export default CartHeaderContainer
