import React from 'react'
import GoButton from '~/cart/gmarket/ko/common/components/GoButton'
import logRender from '~/lib/log-render'

const CartError = (): JSX.Element => {
  logRender()

  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          <strong className="msg">
            <span>일시적인 오류로 장바구니의 상품을</span>{' '}
            <span>호출 할 수 없습니다.</span>
          </strong>
          <GoButton />
        </div>
      </div>
    </div>
  )
}

export default CartError
