import React, { useEffect, useRef, useState } from 'react'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { currencyAmount } from '~/lib/formatter'
import UXEHelper from '~/lib/uxe-helper'

const SmileFreshGaugeBar = ({
  isShippingFeeLoadedAfterChanged,
  totalShippingFee,
  selectedCartUnitCount,
  totalItemPrice,
  smileFreshConditionalFreeBasisCost,
  totalBundleDiscountPrice,
}: {
  isShippingFeeLoadedAfterChanged: boolean
  totalShippingFee: number
  selectedCartUnitCount: number
  totalItemPrice: number
  smileFreshConditionalFreeBasisCost: number
  totalBundleDiscountPrice: number
}): JSX.Element => {
  const [viewType, setViewType] = useState<'FREE_SHIPPING' | 'GAUGE' | 'NONE'>(
    'NONE',
  )
  const [gaugeValue, setGaugeValue] = useState<number>(0)

  // 배송비가 로드완료되었을때만 상태를 바꿔주기위함.
  useEffect(() => {
    if (isShippingFeeLoadedAfterChanged) {
      if (selectedCartUnitCount > 0) {
        if (totalShippingFee === 0) {
          setViewType('FREE_SHIPPING')
        } else {
          setViewType('GAUGE')
        }
      } else {
        setViewType('NONE')
      }
      setGaugeValue(
        totalShippingFee === 0 && selectedCartUnitCount > 0
          ? smileFreshConditionalFreeBasisCost
          : totalItemPrice - totalBundleDiscountPrice,
      )
    }
  }, [
    isShippingFeeLoadedAfterChanged,
    selectedCartUnitCount,
    smileFreshConditionalFreeBasisCost,
    totalItemPrice,
    totalShippingFee,
    totalBundleDiscountPrice,
  ])

  const prevGaugeValue = useRef(gaugeValue)

  useEffect(() => {
    UXEHelper.moveSmileFreshGaugeBar(prevGaugeValue.current, gaugeValue)
    return (): void => {
      prevGaugeValue.current = gaugeValue > 0 ? gaugeValue : 0
    }
  }, [gaugeValue])

  return (
    <div className="box__ad-smilefresh box__smilefresh--gauge">
      {viewType === 'FREE_SHIPPING' ? (
        <div className="text__cart-gauge">
          짝짝&#128079; <strong className="text__emphasis">무료배송</strong>{' '}
          받으시네요!
        </div>
      ) : (
        <CustomAnchor
          href={domains.EMARTMALL_HOME}
          target="_blank"
          className="link__smilefresh"
          data-montelena-acode={areaCodes.SMILE_FRESH_FREE_SHIPPING_GAUGE}
        >
          <span className="text__cart-gauge sprite__cart--after">
            <strong className="text__emphasis">
              <span className="box__format-amount">
                <span className="text__sign" />
                <strong className="text__value">
                  {currencyAmount(
                    smileFreshConditionalFreeBasisCost - gaugeValue,
                  )}
                </strong>
                <span className="text__unit">원</span>
              </span>
            </strong>{' '}
            더 담으면 무료배송
          </span>
        </CustomAnchor>
      )}
      <div className="box__gauge">
        <span className="for-a11y">
          장바구니에 담긴 스마일프래시 금액 및 혜택 안내
        </span>
        <div className="box__gauge-bar">
          <div
            className="image__value"
            role="progressbar"
            aria-valuenow={gaugeValue}
            aria-valuemin={0}
            aria-valuemax={smileFreshConditionalFreeBasisCost}
            aria-live="polite"
          />
        </div>
      </div>
    </div>
  )
}

export default SmileFreshGaugeBar
