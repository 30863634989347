import React from 'react'
import GoButton from '~/cart/gmarket/ko/common/components/GoButton'

const EmptyCart = ({ isMobile }: { isMobile: boolean }): JSX.Element => {
  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          {isMobile && (
            <embed
              type="image/svg+xml"
              className="box__animation"
              src="//pics.gmarket.co.kr/mobile/single/kr/common/neutral_face_with_sweat.svg"
            />
          )}
          <strong className="msg">장바구니에 담긴 상품이 없습니다.</strong>
          <GoButton />
        </div>
      </div>
    </div>
  )
}

export default EmptyCart
