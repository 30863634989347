import { uniq } from 'lodash'
import { apiErrorHandler } from '~/api-error-handler'
import { fetchHideNudging } from '~/apis/cart'
import { fetchAddCheckoutByCart } from '~/apis/cart-checkout'
import { CartUnit } from '~/cart/modules/cart/types'
import {
  confirmAuthentication,
  confirmIncomeDutyItemOrderType,
  confirmOrderExceptSomeItems,
  confirmOverseaShippingNoti,
  defaultCartExceptionHandler,
  openSimpleLayer,
} from '~/cart/modules/complex-actions'
import { CartException } from '~/cart/modules/complex-actions/cart-exception'
import { getAppliedFundingDiscountList } from '~/cart/modules/funding-discount/reducer'
import { getAppliedGroupCouponList } from '~/cart/modules/group-coupon/reducer'
import {
  getBuyUnavailableInfo,
  getDeliveryUnitCouponErrorType,
  getIsAvailableShippingCompanyNotExists,
  getSelectedCartUnitList,
  getSelectedCartUnitShippingWeight,
  getSelectedShippingUnavailableCartUnitCount,
  getTotalSummary,
  RootState,
} from '~/cart/modules/reducers'
import {
  getIsOverseaShipping,
  getShippingCountry,
} from '~/cart/modules/shipping/reducer'
import { getAppliedUnitCouponList } from '~/cart/modules/unit-coupon/reducer'
import { setIsPrepareOrderProcessing } from '~/cart/modules/view/actions'
import { EnumLayerType } from '~/cart/modules/view/types'
import tenantConstants from '~/data/checkout-constants'
import {
  AggregatorPriceLimits,
  AggregatorShippingCompanies,
  ElementIds,
} from '~/data/consts'
import domains from '~/data/domains'
import ActionWrapper, {
  AsyncComplexAction,
  ComplexThunkDispatch,
} from '~/lib/action-wrapper'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import { ERROR_HANDLER_ALERT } from '~/lib/default-error-handlers'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import siteEnv from '~/lib/site-env'
import { ExceptionItemType } from '~/types/enums'

const actionWrapper = new ActionWrapper<RootState>(apiErrorHandler)

type GetState = () => RootState
type ComplexDispatch = ComplexThunkDispatch<RootState>

const assertCartUnit = (
  state: RootState,
  selectedCartUnitList: CartUnit[],
): void => {
  if (selectedCartUnitList.length === 0) {
    throw new CartException('CART-BR7-01') // 주문하실 상품을 선택해주세요.
  }

  if (selectedCartUnitList.length > 30) {
    throw new CartException('CART-BR7-33') // 상품 주문은 최대 30개까지 가능합니다.
  }

  const buyUnavailableCartUnit = selectedCartUnitList.find((cartUnit) =>
    getBuyUnavailableInfo(state, cartUnit.cartUnitId),
  )

  if (buyUnavailableCartUnit) {
    const buyUnavailableInfo = getBuyUnavailableInfo(
      state,
      buyUnavailableCartUnit.cartUnitId,
    )
    if (buyUnavailableInfo) {
      const cartUnitElementId =
        ElementIds.CartUnit + buyUnavailableCartUnit.cartUnitId
      const itemName = buyUnavailableCartUnit.item.itemName
      switch (buyUnavailableInfo.unableOrderType) {
        case 'ITEM_STOP_SELLING':
        case 'VERO_POLICY':
          throw new CartException('CART-BR7-02', cartUnitElementId, itemName)
        case 'ITEM_OUT_OF_STOCK':
          throw new CartException('CART-BR7-03', cartUnitElementId, itemName)
        case 'OPTION_OUT_OF_STOCK':
          throw new CartException('CART-BR7-04', cartUnitElementId, itemName)
        case 'ADDITION_OUT_OF_STOCK':
          throw new CartException('CART-BR7-06', cartUnitElementId, itemName)
        case 'ONLY_SMILE_CLUB_BIZ':
          // club biz 전용 상품 + 일반 상품 다중 선택하여 구매하려 하는 경우
          if (selectedCartUnitList.some((x) => !x.item.isSmileClubBizDeal)) {
            throw new CartException('CART-BR7-46', cartUnitElementId, itemName)
          }
          throw new CartException('CART-BR7-45', cartUnitElementId, itemName)
        case 'ONLY_SMILE_FRESH_RESERVE_BRANCH':
          throw new CartException('CART-BR7-52', cartUnitElementId, itemName)
        case 'ONLY_SMILE_FRESH_DAWN_BRANCH':
          throw new CartException('CART-BR7-53', cartUnitElementId, itemName)
        case 'NO_SMILE_FRESH_BRANCH':
          throw new CartException('CART-BR7-54', cartUnitElementId, itemName)
        case 'BRANCH_CHANGE_POLICY':
          throw new CartException('CART-BR7-55', cartUnitElementId, itemName)
        default:
          throw new CartException('CART-BR7-36', cartUnitElementId, itemName)
      }
    }
  }
}

const assertCoupon = (
  state: RootState,
  selectedCartUnitList: CartUnit[],
): void => {
  if (
    !tenantConstants.IsUsingCouponBoxLayer &&
    state.unitCoupon.couponBoxOpenedCartUnitId
  ) {
    throw new CartException(
      'CART-BR5-13', // 쿠폰 선택 후 적용이 완료되지 않았습니다. 쿠폰적용 버튼을 눌러주십시오.
      ElementIds.CouponSubmitButton +
        state.unitCoupon.couponBoxOpenedCartUnitId,
    )
  }

  // region 배송비가 0원인데 무료배송쿠폰을 사용한 경우
  const deliveryUnitUnavailableCartUnits = selectedCartUnitList.filter(
    (cartUnit) => getDeliveryUnitCouponErrorType(state, cartUnit.cartUnitId),
  )

  if (deliveryUnitUnavailableCartUnits.length > 0) {
    const deliveryUnitUnavailableItemNames = uniq(
      deliveryUnitUnavailableCartUnits.map((x) => x.item.itemName),
    ).join(',')

    throw new CartException(
      'CART-BR7-43', // 무료배송쿠폰이 적용된 상품이 있습니다. 무료배송쿠폰은 결제할 묶음 배송비가 0원인 경우에는 사용할 수 없습니다. 무료배송쿠폰을 적용 취소 후 주문해주세요.
      ElementIds.CouponButton + deliveryUnitUnavailableCartUnits[0].cartUnitId,
      deliveryUnitUnavailableItemNames,
    )
  }
  // endregion

  // 해외배송이 아닌데 해외배송 전용쿠폰이 적용된 경우
  if (
    !getIsOverseaShipping(state.shipping) &&
    selectedCartUnitList.some((cartUnit) =>
      getAppliedUnitCouponList(state.unitCoupon, cartUnit.cartUnitId).some(
        (coupon) => coupon.isOverseaCoupon,
      ),
    )
  ) {
    throw new CartException('CART-BR7-18')
  }
}

const assertOverseaShipping = (
  state: RootState,
  selectedCartUnitList: CartUnit[],
): void => {
  if (!tenantConstants.IsOverseaShippingAvailable) {
    return
  }

  if (getSelectedShippingUnavailableCartUnitCount(state) > 0) {
    throw new CartException('CART-BR7-21') // 선택하신 국가로 배송불가한 상품이 포함되어있습니다. 다시 확인해주세요.
  }

  // 해외배송시 국가별 배송제한조건(수량, 금액, 무게) 체크
  if (getIsOverseaShipping(state.shipping)) {
    const shippingCountry = getShippingCountry(state.shipping)
    const shippingCompany = state.shipping.selectedOverseaShippingCompany
    const selectedSummary = getTotalSummary(state)
    const totalPriceWithoutDiscount = selectedSummary.totalItemPrice
    const totalPriceWithDiscount =
      selectedSummary.totalItemPrice - selectedSummary.totalDiscountPrice

    if (getIsAvailableShippingCompanyNotExists(state)) {
      throw new CartException(
        'CART-BR7-51',
        ElementIds.ChangeCountryWhenOversea,
        shippingCountry.countryName,
      )
    }

    // 중국으로 SF Express 배송 시 구매수량, 금액 초과 체크
    if (
      shippingCompany === 'SFExpress' &&
      shippingCountry.countryType === 'China'
    ) {
      // 구매수량 7개 이상 이거나 구매금액(배송비제외) 30000원 초과 시
      if (selectedCartUnitList.length > 6 || totalPriceWithDiscount > 30000) {
        throw new CartException('CART-BR7-19')
      }
      // 화장품 & 바디/헤어 카테고리 상품이고 결제금액 15000원 초과 시
      if (
        totalPriceWithDiscount > 15000 &&
        selectedCartUnitList.some((x) =>
          tenantConstants.SFExpressUnableLargeCategoryCodes?.includes(
            x.item.largeCategoryCode,
          ),
        )
      ) {
        throw new CartException('CART-BR7-20')
      }
    }

    // Aggregator 배송사 이용시 국가별 구매 가능금액 체크
    if (AggregatorShippingCompanies.includes(shippingCompany)) {
      const isShippingToTaiwan = shippingCountry.countryType === 'Taiwan'
      const priceLimitByCountry =
        AggregatorPriceLimits[shippingCountry.countryType]
      // 대만 해외배송 시 쿠폰 적용 후 금액 (배송비 제외), 다른 국가는 쿠폰 적용 전 금액 (배송비 제외)
      if (
        priceLimitByCountry &&
        priceLimitByCountry <
          (isShippingToTaiwan
            ? totalPriceWithDiscount - selectedSummary.totalCouponPrice
            : totalPriceWithoutDiscount)
      ) {
        throw new CartException(
          'CART-BR7-41',
          ElementIds.ChangeCountryWhenOversea,
          priceLimitByCountry,
        )
      }
    }

    const shippingWeight = getSelectedCartUnitShippingWeight(state)
    if (shippingWeight > 30) {
      if (shippingCompany === 'EMS') {
        throw new CartException('CART-BR7-24')
      } else if (shippingCompany === 'SFExpress') {
        throw new CartException('CART-BR7-25')
      } else if (AggregatorShippingCompanies.includes(shippingCompany)) {
        throw new CartException('CART-BR7-42')
      }
    }
  }
}

const assertCartNudgingTypes = async (
  dispatch: ComplexDispatch,
  state: RootState,
  triggerElement: HTMLElement,
  selectedCartUnitList: CartUnit[],
): Promise<void> => {
  if (getIsOverseaShipping(state.shipping)) {
    const isOverseaPackageNoti = state.cart.cartNudgingTypes.some(
      (cartNudgingType) => cartNudgingType === 'OVERSEA_PACKAGE_NOTI',
    )
    if (isOverseaPackageNoti && selectedCartUnitList.length > 1) {
      const overseaShippingNotiOrderType = await dispatch(
        confirmOverseaShippingNoti(triggerElement),
      )

      await fetchHideNudging({
        cartNudgingType: 'OVERSEA_PACKAGE_NOTI',
      })

      if (
        !overseaShippingNotiOrderType ||
        overseaShippingNotiOrderType === 'ReturnCart'
      ) {
        throw new CartException() // 아무것도 선택 안했을때 or 장바구니로 돌아가기 하면, 창만 끈다
      }
      // 이어서 주문하기 하면 그냥 지나감
    }
  }
}

const assertOrAskCartUnitCombination = async (
  dispatch: ComplexDispatch,
  state: RootState,
  selectedCartUnitList: CartUnit[],
  triggerElement: HTMLElement,
): Promise<void> => {
  if (
    selectedCartUnitList.some(
      (x) =>
        x.item.isPaymentMoneyCategory !==
        selectedCartUnitList[0].item.isPaymentMoneyCategory,
    )
  ) {
    throw new CartException('CART-BR7-44')
  }

  if (
    selectedCartUnitList.some((x) => x.cartUnitType === 'SmileFresh') &&
    selectedCartUnitList.some((x) => x.cartUnitType !== 'SmileFresh')
  ) {
    const smileFreshItemNoes = uniq(
      selectedCartUnitList
        .filter((x) => x.cartUnitType === 'SmileFresh')
        .map((x) => x.item.itemNo),
    )
    const smileFreshItems = smileFreshItemNoes.flatMap((itemNo) => {
      const cartUnit = selectedCartUnitList.find(
        (x) => x.item.itemNo === itemNo,
      )
      if (cartUnit) {
        return [
          {
            itemNo: itemNo,
            itemName: cartUnit.item.itemName,
            itemImageUrl: cartUnit.item.itemImageUrl,
            exceptionItemType:
              cartUnit.cartUnitType === 'SmileFresh'
                ? ('SmileFresh' as ExceptionItemType)
                : ('Unknown' as ExceptionItemType),
          },
        ]
      } else {
        return []
      }
    })

    const isConfirmed = await dispatch<Promise<boolean | void>>(
      confirmOrderExceptSomeItems(
        smileFreshItems,
        '이마트몰',
        ' 상품을',
        '먼저 주문할까요?',
        '다른 상품은 따로 주문해 주세요.😄',
        '네, 이마트몰 먼저 주문할래요.',
        triggerElement,
      ),
    )

    if (!isConfirmed) {
      throw new CartException()
    } else {
      throw new CartException((triggerElement) =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goCheckout(
          triggerElement,
          selectedCartUnitList.filter((x) => x.cartUnitType === 'SmileFresh'),
        ),
      )
    }
  }

  // 예약설치 상품은 최대 5개까지 주문 가능
  if (
    selectedCartUnitList.every((cartUnit) => cartUnit.item.isInstall) &&
    selectedCartUnitList.length > 5
  ) {
    await dispatch(
      openSimpleLayer(EnumLayerType.ConfirmMaxInstallOrder, triggerElement),
    )
    throw new CartException()
  }

  // 예약설치 상품 다른 상품과 함께 구매 불가
  if (
    selectedCartUnitList.some((cartUnit) => cartUnit.item.isInstall) &&
    selectedCartUnitList.some((cartUnit) => !cartUnit.item.isInstall)
  ) {
    const installItemNoes = uniq(
      selectedCartUnitList
        .filter((cartUnit) => cartUnit.item.isInstall)
        .map((cartUnit) => cartUnit.item.itemNo),
    )

    const installItems = installItemNoes.flatMap((itemNo) => {
      const cartUnit = selectedCartUnitList.find(
        (x) => x.item.itemNo === itemNo,
      )
      if (cartUnit) {
        return [
          {
            itemNo: itemNo,
            itemName: cartUnit.item.itemName,
            itemImageUrl: cartUnit.item.itemImageUrl,
            exceptionItemType: cartUnit.item.isInstall
              ? ('Install' as ExceptionItemType)
              : ('Unknown' as ExceptionItemType),
          },
        ]
      } else {
        return []
      }
    })

    const isConfirmed = await dispatch<Promise<boolean | void>>(
      confirmOrderExceptSomeItems(
        installItems,
        '예약설치',
        ' 상품을',
        '먼저 주문할까요?',
        '다른 상품은 설치상품 결제 후 추가 주문 부탁드려요.😅',
        '네, 설치상품 먼저 주문할래요.',
        triggerElement,
      ),
    )

    if (!isConfirmed) {
      throw new CartException()
    } else {
      throw new CartException((triggerElement) =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goCheckout(
          triggerElement,
          selectedCartUnitList.filter((x) => x.item.isInstall),
        ),
      )
    }
  }

  // e쿠폰상품 일반상품 함께 구매 불가. e쿠폰상품은 나머지 상품을 주문하도록 유도
  if (
    selectedCartUnitList.some(
      (x) => x.item.isECoupon !== selectedCartUnitList[0].item.isECoupon,
    )
  ) {
    const exceptECouponItemNoes = uniq(
      selectedCartUnitList
        .filter((cartUnit) => !cartUnit.item.isECoupon)
        .map((cartUnit) => cartUnit.item.itemNo),
    )

    const exceptECouponItems = exceptECouponItemNoes.flatMap((itemNo) => {
      const cartUnit = selectedCartUnitList.find(
        (x) => x.item.itemNo === itemNo,
      )
      if (cartUnit) {
        return [
          {
            itemNo: itemNo,
            itemName: cartUnit.item.itemName,
            itemImageUrl: cartUnit.item.itemImageUrl,
            exceptionItemType: 'ECoupon' as ExceptionItemType, // e쿠폰은 나머지 상품을 보여줘야 하지만, e쿠폰 상황 시에 발생하므로 ECoupon타입으로 고정
          },
        ]
      } else {
        return []
      }
    })

    const isConfirmed = await dispatch<Promise<boolean | void>>(
      confirmOrderExceptSomeItems(
        exceptECouponItems,
        '함께 주문할 수 없는 상품',
        '이',
        '포함되어 있습니다.',
        'e쿠폰을 제외한 상품들 먼저 주문할까요?',
        '네, 이 상품 먼저 주문할래요.',
        triggerElement,
      ),
    )

    if (!isConfirmed) {
      throw new CartException()
    } else {
      throw new CartException((triggerElement) =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goCheckout(
          triggerElement,
          selectedCartUnitList.filter((x) => !x.item.isECoupon),
        ),
      )
    }
  }

  // 도서소득공제상품 일반상품 함께 구매 불가
  if (
    selectedCartUnitList.some((x) => x.item.isIncomeDuty) &&
    selectedCartUnitList.some((x) => !x.item.isIncomeDuty)
  ) {
    if (state.view.languageType === 'Korean') {
      // GPP-6111 - 국문의 경우 도서소득공제상품, 일반상품 함께 구매 가능
      const incomeDutyItemOrderType = await dispatch(
        confirmIncomeDutyItemOrderType(triggerElement),
      )

      if (!incomeDutyItemOrderType) {
        throw new CartException() // 아무것도 선택 안했을때 창만 끈다
      } else if (incomeDutyItemOrderType === 'OnlyIncomeDutyItem') {
        throw new CartException((triggerElement) =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          goCheckout(
            triggerElement,
            selectedCartUnitList.filter((x) => x.item.isIncomeDuty),
          ),
        )
      }
      // 함께구매하면 그냥 지나감
    }
  }

  // 스마일배송 / 당일배송 함께 구매 불가
  if (
    selectedCartUnitList.some((x) => x.cartUnitType === 'ExpressShop') &&
    selectedCartUnitList.some((x) => x.cartUnitType === 'SmileDelivery')
  ) {
    const nonExpressShopItemNoes = uniq(
      selectedCartUnitList
        .filter((x) => x.cartUnitType !== 'ExpressShop')
        .map((x) => x.item.itemNo),
    )
    const nonExpressShopItems = nonExpressShopItemNoes.flatMap((itemNo) => {
      const cartUnit = selectedCartUnitList.find(
        (x) => x.item.itemNo === itemNo,
      )
      if (cartUnit) {
        return [
          {
            itemNo: itemNo,
            itemName: cartUnit.item.itemName,
            itemImageUrl: cartUnit.item.itemImageUrl,
            exceptionItemType:
              cartUnit.cartUnitType === 'SmileDelivery'
                ? ('SmileDelivery' as ExceptionItemType)
                : ('Unknown' as ExceptionItemType),
          },
        ]
      } else {
        return []
      }
    })

    const isConfirmed = await dispatch<Promise<boolean | void>>(
      confirmOrderExceptSomeItems(
        nonExpressShopItems,
        '함께 주문할 수 없는 상품',
        '이',
        '포함되어 있습니다.',
        '당일배송 상품은 따로 주문해 주세요.😄',
        '이 상품 먼저 주문할래요.',
        triggerElement,
      ),
    )

    if (!isConfirmed) {
      throw new CartException()
    } else {
      throw new CartException((triggerElement) =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goCheckout(
          triggerElement,
          selectedCartUnitList.filter((x) => x.cartUnitType !== 'ExpressShop'),
        ),
      )
    }
  }

  // 옥션은 홈플/일반상품 함께 구매 불가
  if (state.view.tenantType === 'Auction') {
    if (
      selectedCartUnitList.some((x) => x.cartUnitType === 'ExpressShop') &&
      selectedCartUnitList.some((x) => x.cartUnitType !== 'ExpressShop')
    ) {
      throw new CartException('CART-BR7-32')
    }
  }
}

const checkIsSelfAuthNeeded = async (
  dispatch: ComplexDispatch,
  state: RootState,
  selectedCartUnitList: CartUnit[],
  triggerElement: HTMLElement,
): Promise<boolean> => {
  // 간편회원
  if (state.buyer.memberType === 'SimpleMember') {
    // 간편회원 && 본인인증필요상품 주문시 Layer Confirm 노출
    const isAuthNeededItem = selectedCartUnitList.some(
      (x) => x.isNeedAuthentication,
    )
    // 간편회원 && 해외배송 주문시 Layer Confirm 노출
    const isOverseaShipping = getIsOverseaShipping(state.shipping)

    if (isAuthNeededItem || isOverseaShipping) {
      const isConfirmed = await dispatch<Promise<boolean | void>>(
        confirmAuthentication(
          isAuthNeededItem
            ? __(
                'ESCROW_BASKET_TEXT_250',
                '본인인증이 필요한 상품이 있어\n인증 후에 주문이 가능합니다.',
              ) // 시스템 confirm 쓸땐 '인증하시겠습니까?' 추가
            : '해외 배송은 본인인증 후에 주문이 가능합니다.',
          triggerElement,
        ),
      )
      if (!isConfirmed) {
        throw new CartException()
      }
      return true
    }
  }
  return false
}

const addCheckoutByCart = async (
  state: RootState,
  selectedCartUnitList: CartUnit[],
): Promise<{
  checkoutUnitIds: number[]
  txKey: string
}> => {
  const cartUnitIdList = selectedCartUnitList.map((_) => _.cartUnitId)

  const coupons: cartAPI.AddCheckoutCouponCommandRequest[] =
    getAppliedGroupCouponList(state.groupCoupon, cartUnitIdList)
      .map<cartAPI.AddCheckoutCouponCommandRequest>((x) => {
        return {
          cartUnitIds: x.cartUnitIdList,
          couponNo: x.couponIssueNo,
          couponType: x.couponType,
        }
      })
      .concat(
        cartUnitIdList
          .flatMap((cartUnitId) =>
            getAppliedUnitCouponList(state.unitCoupon, cartUnitId).filter(
              (x) => x.couponIssueNo,
            ),
          )
          .map<cartAPI.AddCheckoutCouponCommandRequest>((x) => {
            return {
              cartUnitIds: [x.cartUnitId],
              couponNo: x.couponIssueNo,
              couponType: x.couponType,
            }
          }),
      )

  const extraDiscounts = selectedCartUnitList.flatMap((cartUnit) => {
    return getAppliedFundingDiscountList(
      state.fundingDiscount,
      cartUnit.cartUnitId,
    ).map<cartAPI.AddCheckoutDiscountCommandRequest>((discount) => ({
      cartUnitIds: [cartUnit.cartUnitId],
      discountNo: discount.discountPolicyNo,
      discountType: 'Extra',
    }))
  })

  const addCheckoutCommandResponse = await fetchAddCheckoutByCart({
    cartUnitIds: cartUnitIdList,
    coupons: coupons,
    discounts: extraDiscounts,
    branchServiceType: state.smileFresh.currentBranchServiceType,
  })
  if (
    addCheckoutCommandResponse.isSuccess &&
    addCheckoutCommandResponse.checkoutUnitIds
  ) {
    return {
      checkoutUnitIds: addCheckoutCommandResponse.checkoutUnitIds,
      txKey: addCheckoutCommandResponse.txKey
        ? addCheckoutCommandResponse.txKey
        : '',
    }
  } else {
    throw new CartException(addCheckoutCommandResponse.message)
  }

  /* FIXME minhjang A 주문서 담기 호출 전환 백업용
  if (state.view.tenantType === 'Auction') {
    const prepareOrderResult = await fetchPrepareOrder({
      cartUnitIds: cartUnitIdList,
      coupons: coupons,
      discounts: extraDiscounts,
    })
    if (
      prepareOrderResult.success &&
      prepareOrderResult.data &&
      prepareOrderResult.data instanceof Array &&
      prepareOrderResult.data.length > 0
    ) {
      return {
        checkoutUnitIds: prepareOrderResult.data
          .map((x) =>
            typeof x === 'number' ? x : typeof x === 'string' ? parseInt(x) : 0,
          )
          .filter((x) => !!x),
        txKey: '',
      }
    } else {
      throw new CartException(prepareOrderResult.message)
    }
  } else {
    const addCheckoutCommandResponse = await fetchAddCheckoutByCart({
      cartUnitIds: cartUnitIdList,
      coupons: coupons,
      discounts: extraDiscounts,
      branchServiceType: state.smileFresh.currentBranchServiceType,
    })
    if (
      addCheckoutCommandResponse.isSuccess &&
      addCheckoutCommandResponse.checkoutUnitIds
    ) {
      return {
        checkoutUnitIds: addCheckoutCommandResponse.checkoutUnitIds,
        txKey: addCheckoutCommandResponse.txKey
          ? addCheckoutCommandResponse.txKey
          : '',
      }
    } else {
      throw new CartException(addCheckoutCommandResponse.message)
    }
  }
   */
}

const getCheckoutUrl = async (
  checkoutUnitIdList: number[],
  txKey: string,
): Promise<string> => {
  let domain = ''

  // 로컬 개발시 장바구니 주문서 함께 개발하기 편하도록 로컬 주문서 떠있으면 그리로 보낸다
  if (domains.CHECKOUT_LOCAL && siteEnv.env === 'local' && window.fetch) {
    try {
      await window.fetch(
        'http://checkout-local.gmarket.co.kr:3000/server/health-check',
      )

      domain = domains.CHECKOUT_LOCAL
    } catch (e) {
      // DO NOTHING
    }
  } else {
    domain = domains.CHECKOUT
  }

  return formatString(domain, encodeURIComponent(checkoutUnitIdList.join(',')))
    .concat(txKey != '' && txKey != null ? `&txKey=${txKey}` : '') // 선물하기 주문서로 가기 위하여 txKey 추가
    .concat(`&autoCoupon=false`) //장바구나애서는 주문서의 최적 쿠폰 추천 및 자동 적용 기능을 사용하지 않음
}

export const goCheckout = (
  triggerElement: HTMLElement,
  cartUnitList?: CartUnit[],
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const state = getState()
      const selectedCartUnitList: CartUnit[] =
        cartUnitList && cartUnitList.length > 0
          ? cartUnitList
          : getSelectedCartUnitList(state, true)

      assertCartUnit(state, selectedCartUnitList)

      assertCoupon(state, selectedCartUnitList)

      assertOverseaShipping(state, selectedCartUnitList)

      await assertCartNudgingTypes(
        dispatch,
        state,
        triggerElement,
        selectedCartUnitList,
      )

      await assertOrAskCartUnitCombination(
        dispatch,
        state,
        selectedCartUnitList,
        triggerElement,
      )

      const isSelfAuthNeeded = await checkIsSelfAuthNeeded(
        dispatch,
        state,
        selectedCartUnitList,
        triggerElement,
      )

      dispatch(setIsPrepareOrderProcessing(true))

      const responseResult = await addCheckoutByCart(
        state,
        selectedCartUnitList,
      )

      const checkoutUrl = await getCheckoutUrl(
        responseResult.checkoutUnitIds,
        responseResult.txKey,
      )

      let redirectUrl = checkoutUrl

      if (isSelfAuthNeeded) {
        redirectUrl = formatString(
          domains.SELF_AUTH,
          encodeURIComponent(checkoutUrl),
        )
      } else if (!state.buyer.isLogin) {
        redirectUrl = formatString(
          domains.SIGN_IN_ORDER || domains.SIGN_IN,
          encodeURIComponent(checkoutUrl),
        )
      }

      const appScheme = AppSchemeHelper.getOpenCheckoutWebviewScheme(
        state.view.isApp,
        state.view.appInfo?.appType,
      )
      if (appScheme) {
        window.location.href = formatString(
          appScheme,
          encodeURIComponent(redirectUrl),
        )
        dispatch(setIsPrepareOrderProcessing(false))
      } else {
        window.location.href = redirectUrl
      }
    },
    defaultCartExceptionHandler(triggerElement, ERROR_HANDLER_ALERT),
    (dispatch) => (): void => {
      dispatch(setIsPrepareOrderProcessing(false))
    },
  )
