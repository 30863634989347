/* tslint:disable */
/* eslint-disable */

import domains from '~/data/domains'

/**
 * UXE에서 제공하는 함수들은 다 이쪽으로 몬다
 * type체크없이 실행한다
 */
class Montelena {
  /**
   *
   * @param actionType {MontelenaActionType}
   * @param areaCode {string}
   * @param areaValues {MontelenaAreaValueType}
   * @param source {string}
   */
  static logImpression = (actionType, areaCode, areaValues, source) => {
    try {
      window.montelena.sendEvent(
        actionType,
        areaCode,
        areaValues,
        source,
        domains.MONTELENA_SERVICE_NAME,
        domains.MONTELENA_API_KEY,
      )
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * 장바구니 로딩 시 선택된 탭 기준으로 수동 PV 로깅
   * Montelena에서 제공하는 sendViewManaual 함수 호출
   * https://wiki.gmarket.com/display/MON/3.+PV+direct+logging+%3A+browser+to+server
   */
  static sendViewManual = () => {
    try {
      if (window.montelena && window.montelena.sendViewManual) {
        let url = window.location.href
        const pattern = /\/cart\/#\/$/
        // CHKP-11338 전체 탭 선택하여 최초 로딩 된 경우,
        // cart.{tenant}.co.kr/{language}/{device}/#/ -> cart.{tenant}.co.kr/{language}/{device}/all#/ 으로 변경하여 전송
        // 전체 탭 이외는 url 그대로 전송
        if (pattern.test(url)) {
          url = url.replace(pattern, '/cart/all#/')
        }
        window.montelena.sendViewManual({}, url)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default Montelena
