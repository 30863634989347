import React, { useEffect } from 'react'
import { fetchHideNudging } from '~/apis/cart'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { AppType, OverseaShippingNotiOrderType } from '~/types/enums'

type LayerNudgingUniverseClubAutoRenewalProp = LayerProps & {
  onCloseLayerAsync: (closeImmediately?: boolean) => Promise<void>
  detailData?: {
    onConfirm: (orderType?: OverseaShippingNotiOrderType) => void
  }
  isActivated: boolean
  isApp: boolean
  isAndroid: boolean
  appType?: AppType
  appVersion?: string
}

const LayerNudgingUniverseClubAutoRenewal = ({
  layerId,
  onCloseLayerAsync,
  isActivated,
  isApp,
  isAndroid,
  appType,
  appVersion,
}: LayerNudgingUniverseClubAutoRenewalProp): JSX.Element => {
  logRender()

  const [animating, setAnimating] = React.useState(false)

  useEffect(() => {
    fetchHideNudging({
      cartNudgingType: 'UNIVERSE_CLUB_AUTO_RENEWAL_NUDGE',
    }).catch(ERROR_HANDLER_DO_NOTHING())
  }, []) // mount 시점에 1회 호출

  useEffect(() => {
    if (isActivated) {
      setTimeout(() => {
        setAnimating(true)
      }, 10)
    }
  }, [isActivated])

  const appScheme = AppSchemeHelper.getOpenWindowScheme(
    isApp,
    isAndroid,
    appType,
    appVersion,
  )

  return (
    <div
      className={`box__layer box__layerpop--club-extend ${
        isActivated && animating ? 'box__layer--active' : ''
      }`}
      id={layerId}
      role="dialog"
      aria-hidden={isActivated ? 'true' : 'false'}
    >
      <div
        className="box__layer-container"
        // tabIndex=0
      >
        <button
          className="button__close"
          onClick={() => onCloseLayerAsync(true)}
        >
          <span className="for-a11y">닫기</span>
        </button>
        <p className="text__content-notice">
          <span className="icon__club">
            <span className="for-a11y">유니버스 클럽</span>
          </span>
        </p>
        <div className="box__content">
          <p className="text__content-info">
            유니버스 클럽 자동결제 신청하면
            <br />
            연간회원 연장 시<br />
            <span className="text__ellipsis">스마일캐시 10,000원</span> 즉시
            지급!
          </p>
        </div>
        <div className="box__button-club-extend">
          <button
            type="button"
            className="button__request-benefit"
            data-montelena-acode={areaCodes.CLUB_AUTO_RENEWAL_OK}
            onClick={() => {
              if (appScheme) {
                window.location.href = formatString(
                  appScheme,
                  domains.UNIVERSE_CLUB_AUTO_RENEWAL,
                )
              } else {
                window.open(
                  domains.UNIVERSE_CLUB_AUTO_RENEWAL,
                  '_blank',
                  'width=400, height=650',
                )
              }

              onCloseLayerAsync(true)
            }}
          >
            신청하고 혜택 받기
          </button>
          <button
            type="button"
            className="button__cancel"
            data-montelena-acode={areaCodes.CLUB_AUTO_RENEWAL_CLOSE}
            onClick={() => onCloseLayerAsync(true)}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  )
}

export default LayerNudgingUniverseClubAutoRenewal
