import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { CartTabType } from '~/types/enums'

const getHomeInfo = (
  cartTab: CartTabType,
): { url: string; text: string; acode?: string } => {
  if (cartTab === 'SmileDelivery' && domains.SMILE_DELIVERY_HOME) {
    return {
      text: '스마일배송 상품보기',
      url: domains.SMILE_DELIVERY_HOME,
      acode: areaCodes.GO_SMILE_DELIVERY_HOME,
    }
  } else if (cartTab === 'SmileFresh' && domains.EMARTMALL_HOME) {
    return {
      text: '이마트몰 상품보기',
      url: domains.EMARTMALL_HOME,
      acode: areaCodes.GO_EMARTMALL_HOME,
    }
  } else if (cartTab === 'ExpressShop' && domains.EXPRESS_SHOP_HOME) {
    return {
      text: '당일배송 상품보기',
      url: domains.EXPRESS_SHOP_HOME,
      acode: areaCodes.GO_EXPRESS_SHOP_HOME,
    }
  } else {
    return {
      text: '홈으로 가기',
      url: domains.HOME,
      acode: areaCodes.GO_HOME,
    }
  }
}

const GoButton = (): JSX.Element => {
  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )
  const homeInfo = getHomeInfo(currentCartTab)

  return (
    <CustomAnchor
      href={homeInfo.url}
      className="btn_action"
      data-montelena-acode={homeInfo.acode || ''}
    >
      <span className="btn_text">{homeInfo.text}</span>
    </CustomAnchor>
  )
}

export default GoButton
